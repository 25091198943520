import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = d3_format('.2p');
var default_MN_workplaces = format_percent(default_params.MN.original.w);
var default_VA_workplaces = format_percent(default_params.VA.original.w);
var AirlawsTourView = TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Smoke-free Air Laws Policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      backdrop: true,
      element: '[data-id="airlaws"] .policy-choropleth-container',
      placement: 'right',
      title: 'Existing policy',
      content: "<p>Each state in the U.S. has an existing smoke-free air laws policy. The colors in this map represent the current policy coverage using a weighted average of the coverage of each of the three venues. States that have a higher existing coverage are colored lighter while states that have a low existing coverage are colored darker.</p>\n          <p>Hover your mouse pointer over the map to see the specific coverage for each state.\n          Click your mouse on a state to configure the tool for that state.</p>"
    }, {
      backdrop: true,
      element: '[data-id="airlaws"] .policy-choropleth-container',
      placement: 'right',
      title: 'Existing policy',
      content: "<p>For example, the state of Minnesota has 100% smoke-free air law coverage across restaurants and bars, with ".concat(default_MN_workplaces, " of workers reporting that their workplace is smoke-free. States like Minnesota with high coverage have the <strong>least to gain</strong> from a smoke-free air laws policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'MN'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="airlaws"] .policy-choropleth-container',
      placement: 'right',
      title: 'Existing policy',
      content: "<p>In contrast, the state of Virginia has 0% smoke-free air law coverage across restaurants and bars, with ".concat(default_VA_workplaces, " of workers reporting that their workplace is smoke-free. States like Virginia with low average coverage have the <strong>most to gain</strong> from a smoke-free air laws policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'VA'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      element: '[data-id="airlaws"] #airlaws-region',
      placement: 'bottom',
      backdrop: true,
      title: 'How to use the tool',
      content: "<p>To determine the effects of a smoke-free air policy start by choosing the state you are interested in\n          (either a specific state or all states) by using the drop-down menu or clicking on the map to the right.\n          This will set the initial model parameters to match the current smoke-free environment for workplaces, restaurants, and bars of that state.</p>"
    }, {
      element: '[data-id="airlaws"] #airlaws-policy-year',
      placement: 'bottom',
      backdrop: true,
      title: 'Choose the policy start year',
      content: "<p>Then choose the start year of your new policy (default=".concat(default_policy_year, ").</p>")
    }, {
      element: '[data-id="airlaws"] #ban_w',
      title: 'Choose proposed coverage',
      placement: 'bottom',
      backdrop: true,
      content: "<p>Select the proposed smoke-free air law you wish to simulate by changing coverage for each venue using the dropdown menus.</p>"
    }, {
      element: '[data-id="airlaws"] .airlaws-initial-values',
      title: 'Adjust initial coverage',
      backdrop: true,
      placement: 'bottom',
      content: "<p>The initial coverage is automatically set based on your choice of region/state. However you can override the default values by clicking the <svg class=\"icon-component\" style=\"margin-right: -10px;\"><use xlink:href=\"#icon-lock\" style=\"fill: #BBB\"></use></svg> symbol.</p><p>For example, you may wish to see the impact of a smoking law in workplaces according to the level of smoke-free legislation coverage, rather than on the percent of the population that reports working in non-smoking environments.</p>"
    }, {
      element: '[data-id="airlaws"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      content: "<p>A convenient summary of your new policy is provided to help you better understand the input parameters used to generate the estimated outcomes.</p>",
      backdrop: true
    }, {
      element: '[data-id="airlaws"] #explorer-overview',
      title: 'Results',
      placement: 'top',
      backdrop: true,
      content: "<p>The values shown in the charts below are derived from simulations, using the Smoking History Generator population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention and Surveillance Modeling Network (CISNET)</a>.\n          This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p>\n          <p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      element: '',
      orphan: true,
      backdrop: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the smoke-free air laws policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});
export default AirlawsTourView;