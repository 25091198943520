import { helpers, Labels } from 'd3-compose';
import { includes } from 'lodash';
import { withoutType } from './end-value-points';
var property = helpers.property,
  di = helpers.di;
Labels.extend('EndYearLabels', {
  initialize: function initialize(options) {
    var _this = this;
    this.base.classed('end-year-labels', true);
    Labels.prototype.initialize.call(this, options);
    var onEnterSeries = function onEnterSeries(series) {
      _this.highlight(withoutType(series.key));
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onLeaveSeries = function onLeaveSeries() {
      _this.highlight(null);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onFilterSeries = function onFilterSeries(filtered, active) {
      _this.filtered(filtered);
      if (_this.highlight() === active) {
        _this.highlight(null);
      }
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onEndValue = function onEndValue(end_value) {
      _this.end_value(end_value);
      _this.draw(_this.data(), true);
    };
    var onTransientValue = function onTransientValue(end_value) {
      _this.transient_end_value(end_value);
      _this.draw(_this.data(), true);
    };
    this.on('attach', function () {
      _this.container.on('enter:series', onEnterSeries);
      _this.container.on('leave:series', onLeaveSeries);
      _this.container.on('filter:series', onFilterSeries);
      _this.container.on('change:end_value', onEndValue);
      _this.container.on('change:transient_end_value', onTransientValue);
    });
    this.on('detach', function () {
      _this.container.off('enter:series', onEnterSeries);
      _this.container.off('leave:series', onLeaveSeries);
      _this.container.off('filter:series', onFilterSeries);
      _this.container.off('change:end_value', onEndValue);
      _this.container.off('change:transient_end_value', onTransientValue);
    });
  },
  draw: function draw(data, changing) {
    if (!changing) {
      this.original_data(data);
    }
    var end_year = this.xScale().domain()[1];
    var end_value = this.transient_end_value() || this.end_value();
    var fadein = true;
    if (end_value && Math.abs(end_year - end_value) < 10) {
      data = [{
        values: []
      }];
      fadein = false;
    } else {
      data = this.original_data();
    }
    if (changing && data.length === this.data().length) {
      // No changes, skip redraw
      return;
    } else if (this.__skip_transition) {
      this.delay(0);
      this.duration(0);
    } else if (changing) {
      // Only transition on fadeout
      this.delay(0);
      this.duration(fadein ? 500 : 0);
    }
    return Labels.prototype.draw.call(this, data);
  },
  original_data: property(),
  end_value: property(),
  transient_end_value: property(),
  offset: property({
    default_value: 10
  }),
  highlight: property(),
  filtered: property({
    default_value: []
  }),
  labelClass: di(function (chart, d, i) {
    var highlight = chart.highlight();
    var filtered = chart.filtered();
    var key = withoutType(chart.seriesData.call(this, d, i).key);
    var is_highlighted = highlight === key;
    var is_filtered = filtered && includes(filtered, key);
    var is_hidden = is_filtered && !is_highlighted;
    var is_faded = is_filtered && is_highlighted || !is_hidden && highlight && !is_highlighted;
    return ['chart-label', d['class'], is_hidden && 'is-hidden', is_faded && 'is-faded'].filter(Boolean).join(' ');
  })
});