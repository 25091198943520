import config from '../config';
var MIN_AGE = 8;
var MAX_AGE = config.MAX_AGE;
var MIN_YEAR = 2018;
var MAX_YEAR = 2100;
export default function yearToAge(year) {
  var age = year - 2100 + MAX_AGE;
  if (age < MIN_AGE) return MIN_AGE;
  if (age > MAX_AGE) return MAX_AGE;
  return age;
}
export function ageToYear(age) {
  var year = age - MAX_AGE + 2100;
  if (year < MIN_YEAR) return MIN_YEAR;
  if (year > MAX_YEAR) return MAX_YEAR;
  return year;
}
export function policyYearToStartAge(policy_year) {
  return policy_year - 2010;
}