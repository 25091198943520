import { bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { default_data_folder, default_policy_year, default_horizon, format } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withStateDefaults, withEditInitial, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import TaxesTourView from '../../views/taxes-tour-view';
var getStateDefaults = function getStateDefaults(us_state) {
  var defaults = config.default_params[us_state];
  return {
    initial_price: defaults.ppp,
    default_price: defaults.ppp,
    unrounded_price: defaults.unrounded_ppp
  };
};
export default PageView.extend({
  title: 'Cigarette Taxes',
  template: App.template('taxes'),
  defaults: function defaults() {
    return new Model({
      policy: 'taxes',
      policy_year: default_policy_year,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_horizon,
      tax_increase: 1,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: []
    });
  },
  conversions: {
    policy_year: Number,
    initial_price: Number,
    tax_increase: Number
  },
  excludeFromQuerystring: ['unrounded_price', 'default_price', 'by_cohort', 'age_period'],
  initialize: function initialize() {
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    withStateDefaults(this, getStateDefaults);
    withEditInitial(this, getStateDefaults);
    withEndValue(this);
    withFiltered(this);
    withNotify(this, 'taxes');
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: false
    });
    this.final_price = computed(this.model, ['initial_price', 'tax_increase'], function (initial_price, tax_increase) {
      return format.money(parseFloat(initial_price) + parseFloat(tax_increase));
    });
    this.choropleth = charts.choropleth;
    this.tour = new TaxesTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
  },
  config: config,
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default current price' : 'Click to change current price (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});