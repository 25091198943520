function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { isEqual, includes } from 'lodash';
import { svg, mouse as d3_mouse, select as d3_select } from 'd3';
import { Chart, Legend, mixins, helpers } from 'd3-compose';
import getEndYearData from '../utils/get-end-year-data';
var property = helpers.property;
var Mixed = helpers.mixin(Chart, mixins.Series, mixins.XY, mixins.Transition);
var id = 0;
Mixed.extend('EndYearLines', {
  initialize: function initialize() {
    var _this = this;
    Mixed.prototype.initialize.apply(this, arguments);
    this.seriesLayer('Lines', this.base.append('g').classed('chart-lines', true), {
      dataBind: function dataBind(data) {
        var yKey = this.chart().yKey();
        return this.selectAll('path').data(function (d, i, j) {
          var values = data.call(this, d, i, j);

          // For undefined values, return empty array
          var no_values = values.length && values[0][yKey] == null;
          if (no_values) {
            values = [];
          }
          return [values];
        });
      },
      insert: function insert() {
        var chart = this.chart();
        return this.append('path').attr('class', 'chart-line').attr('clip-path', "url(#".concat(chart.clip_id, ")"));
      },
      events: {
        merge: function merge() {
          var chart = this.chart();

          // If x-domain has changed, draw new data with previous scale to prevent "tearing" in transition
          //
          // Note: don't update for "complete shift" (new max < old min or new min > old max)
          var previous_domain = chart.previousXScale && chart.previousXScale.domain();
          var current_domain = chart.xScale().domain();
          var changed_scale = previous_domain && !isEqual(previous_domain, current_domain);
          var complete_shift = !previous_domain || current_domain[1] < previous_domain[0] || current_domain[0] > previous_domain[1];
          if (changed_scale && !complete_shift) {
            // Draw line with previous scale
            var line = svg.line().x(function (d, i) {
              var value = chart.xValue.call(this, d, i);
              return chart.previousXScale(value);
            }).y(function (d, i) {
              var value = chart.yValue.call(this, d, i);
              return chart.previousYScale(value);
            }).interpolate(chart.interpolate());
            this.attr('d', line);
          }
          chart.previousXScale = chart.xScale();
          chart.previousYScale = chart.yScale();
          var filtered = chart.filtered();
          this.each(function (d, i, j) {
            var parent = this.parentElement;
            var series = chart.seriesData.call(this, d, i, j);
            if (!parent || !series) return false;
            var is_filtered = includes(filtered, removePolicyAndBaseline(series.key));
            d3_select(parent).classed('is-filtered', is_filtered);
          });
        },
        'merge:transition': function mergeTransition() {
          var chart = this.chart();
          chart.setupTransition(this);
          var line = svg.line().x(chart.x).y(chart.y).interpolate(chart.interpolate());
          this.attr('d', line).attr('style', this.itemStyle);
        }
      }
    });
    var stored;
    var onEndValue = function onEndValue(end_value) {
      var override = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!override) stored = end_value;
      if (override && !end_value) end_value = stored;
      if (!end_value) return;
      var data = getEndYearData(_this.data(), end_value);
      _this.container.trigger('end-year', data);
    };
    var last;
    var hover_area = this.base.append('rect').attr('class', 'lines-hover').attr('style', 'opacity: 0').on('mousemove', function () {
      var position = d3_mouse(hover_area.node());
      var x = Math.round(inverse(this.xScale(), position[0]));
      var x_key = this.xKey();
      var min_x = this.startYear();
      if (x_key == 'year' && x < min_x) x = min_x;
      if (x == last) return;
      last = x;
      onEndValue(_defineProperty({}, x_key, x), true);
    }.bind(this)).on('mouseleave', function () {
      last = null;
      onEndValue(null, true);
    });
    this.clip_id = 'line-clip-' + id++;
    var clip_path = this.base.append('defs').append('clipPath').attr('id', this.clip_id).append('rect').attr('x', 0).attr('y', 0);
    this.on('draw', function () {
      hover_area.attr('width', _this.width()).attr('height', _this.height());
      clip_path.attr('width', _this.width()).attr('height', _this.height());
    });
    var onEnterSeries = function onEnterSeries(series, filtered) {
      _this.filtered(filtered);
      var key = removePolicyAndBaseline(series.key);
      var filtered_keys = _this.filtered();
      var highlight = includes(filtered_keys, key);
      _this.base.select('.chart-lines').selectAll('.chart-series').classed('is-faded', function (d) {
        // Highlight both policy and baseline
        var series_key = removePolicyAndBaseline(d.key);
        return highlight ? series_key === key : series_key != key && !includes(filtered_keys, series_key);
      });
    };
    var onLeaveSeries = function onLeaveSeries() {
      _this.base.select('.chart-lines').selectAll('.chart-series').classed('is-faded', false);
    };
    var onFilterSeries = function onFilterSeries(filtered, active) {
      _this.filtered(filtered);
      var keys = _this.filtered();
      _this.base.select('.chart-lines').selectAll('.chart-series').classed('is-faded', function (d) {
        return active && removePolicyAndBaseline(d.key) === removePolicyAndBaseline(active);
      }).classed('is-filtered', function (d) {
        return keys.length && includes(keys, removePolicyAndBaseline(d.key));
      });
    };
    this.on('attach', function () {
      _this.container.on('enter:series', onEnterSeries);
      _this.container.on('leave:series', onLeaveSeries);
      _this.container.on('filter:series', onFilterSeries);
      _this.container.on('background:filter:series', onFilterSeries);
      _this.container.on('end-value', onEndValue);
    });
    this.on('detach', function () {
      _this.container.off('enter:series', onEnterSeries);
      _this.container.off('leave:series', onLeaveSeries);
      _this.container.off('filter:series', onFilterSeries);
      _this.container.off('background:filter:series', onFilterSeries);
      _this.container.off('end-value', onEndValue);
    });
  },
  interpolate: property({
    default_value: 'monotone'
  }),
  startYear: property({
    default_value: 2030
  }),
  filtered: property({
    default_value: []
  })
});
var lineSwatch = Legend.swatches.Lines;
Legend.registerSwatch('EndYearLines', lineSwatch);

// Find un-scaled inverse value (for linear scales)
export function inverse(scale, value) {
  var domain = scale.domain();
  var bounds = [scale(domain[0]), scale(domain[1])];

  // domain: 0 ---   ?   --- 1
  // bounds: 0 --- value --- 1
  //
  //   value - bounds[0]         ? - domain[0]
  // --------------------- = ---------------------
  // bounds[1] - bounds[0]   domain[1] - domain[0]
  //
  //  A     ? - domain[0]
  // --- = ---------------
  //  B           C
  //
  // ? = A / B * C + domain[0]

  var A = value - bounds[0];
  var B = bounds[1] - bounds[0];
  var C = domain[1] - domain[0];
  return A / B * C + domain[0];
}
export function removePolicyAndBaseline(key) {
  return key.replace('-reduction', '').split('-').slice(0, -1).join('-');
}