import { helpers, mixins, Chart } from 'd3-compose';
import { assign, includes } from 'lodash';
import { svg as d3_svg } from 'd3';
var mixin = helpers.mixin,
  property = helpers.property,
  di = helpers.di;
var Series = mixins.Series,
  XY = mixins.XY,
  Transition = mixins.Transition;
var Base = mixin(Chart, Series, XY, Transition);
Base.extend('EndValuePoints', {
  initialize: function initialize(options) {
    var _this = this;
    Base.prototype.initialize.call(this, options);
    this.base.classed('chart-end-value-points', true);
    this.__last_transient_value = null;
    this.__skip_transition = true;
    var layers = {
      points: this.base.append('g'),
      line: this.base.append('g')
    };
    this.seriesLayer('points', layers.points, {
      dataBind: function dataBind(data) {
        var chart = this.chart();
        return this.selectAll('path').data(data, chart.key);
      },
      insert: function insert() {
        return this.append('path');
      },
      events: {
        'merge:transition': function mergeTransition() {
          var chart = this.chart();
          if (chart.__skip_transition) {
            this.duration(0);
          } else {
            chart.setupTransition(this);
          }
          this.attr('class', chart.pointClass);
          //TODO: Extract series-type -> symbol information
          // into configuration file.
          var maleSymbol = d3_svg.symbol().type("triangle-up").size(25);
          var femaleSymbol = d3_svg.symbol().type("square").size(35);
          var defaultSymbol = d3_svg.symbol().type("circle").size(35);
          this.attr('d', function (d) {
            if (d.result_type.includes("females")) {
              return femaleSymbol(d);
            } else if (d.result_type.includes("males")) {
              return maleSymbol(d);
            } else {
              return defaultSymbol(d);
            }
          });
          this.attr('transform', function (d) {
            return "translate(".concat(chart.x(d), ", ").concat(chart.y(d), ")");
          });
        },
        exit: function exit() {
          this.remove();
        }
      }
    });
    this.layer('line', layers.line, {
      dataBind: function dataBind(data) {
        var chart = this.chart();
        var max_value = data.reduce(function (memo, series) {
          if (series.is_transient) return memo;
          if (!memo) return series;
          return chart.yValue(series.values[0]) > chart.yValue(memo.values[0]) ? series : memo;
        }, undefined);
        var max_transient = data.reduce(function (memo, series) {
          if (!series.is_transient) return memo;
          if (!memo) return series;
          return chart.yValue(series.values[0]) > chart.yValue(memo.values[0]) ? series : memo;
        }, undefined);
        var values = [];
        if (max_value) values.push(max_value);
        if (max_transient) values.push(max_transient);
        return this.selectAll('line').data(values, function (d) {
          return d.key;
        });
      },
      insert: function insert() {
        return this.append('line');
      },
      events: {
        enter: function enter() {
          var chart = this.chart();
          this.attr('x1', chart.x1).attr('x2', chart.x2).attr('y1', chart.y1).attr('y2', chart.y2).attr('class', chart.lineClass);
        },
        'update:transition': function updateTransition() {
          var chart = this.chart();
          if (chart.__skip_transition) {
            this.duration(0);
            chart.__skip_transition = false;
          } else {
            chart.setupTransition(this);
          }
          this.attr('x1', chart.x1).attr('x2', chart.x2).attr('y1', chart.y1).attr('y2', chart.y2).attr('class', chart.lineClass);
        },
        exit: function exit() {
          this.remove();
        }
      }
    });
    var onEnterSeries = function onEnterSeries(series) {
      _this.highlight(withoutType(series.key));
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onLeaveSeries = function onLeaveSeries() {
      _this.highlight(null);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onFilterSeries = function onFilterSeries(filtered, active) {
      _this.filtered(filtered);
      if (_this.highlight() === active) {
        _this.highlight(null);
      }
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onChangeEndValue = function onChangeEndValue(end_value) {
      _this.__last_transient_value = null;
      _this.end_value(end_value);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onTransientEndValue = function onTransientEndValue(end_value) {
      if (!end_value) {
        _this.__last_transient_value = _this.transient_end_value();
      }
      _this.transient_end_value(end_value);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    this.on('attach', function () {
      _this.container.on('enter:series', onEnterSeries);
      _this.container.on('leave:series', onLeaveSeries);
      _this.container.on('filter:series', onFilterSeries);
      _this.container.on('change:end_value', onChangeEndValue);
      _this.container.on('change:transient_end_value', onTransientEndValue);
    });
    this.on('detach', function () {
      _this.container.off('enter:series', onEnterSeries);
      _this.container.off('leave:series', onLeaveSeries);
      _this.container.off('filter:series', onFilterSeries);
      _this.container.off('change:end_value', onChangeEndValue);
      _this.container.off('change:transient_end_value', onTransientEndValue);
    });
  },
  r: property({
    default_value: 3
  }),
  end_value: property(),
  transient_end_value: property(),
  highlight: property(),
  filtered: property({
    default_value: []
  }),
  x1: di(function (chart, d) {
    var max = d.values[0];
    return max ? chart.x(max, 0, 0) : 0;
  }),
  x2: di(function (chart, d) {
    var max = d.values[0];
    return max ? chart.x(max, 0, 0) : 0;
  }),
  y1: di(function (chart, d) {
    var max = d.values[0];
    return max ? chart.yScale()(0, 0) : 0;
  }),
  y2: di(function (chart, d) {
    var max = d.values[0];
    return max ? chart.y(max, 0, 0) : 0;
  }),
  pointClass: di(function (chart, d, i) {
    var highlight = chart.highlight();
    var filtered = chart.filtered();
    var key = withoutType(chart.seriesData.call(this, d, i).key);
    var is_highlighted = highlight === key;
    var is_filtered = filtered && includes(filtered, key);
    var is_hidden = is_filtered && !is_highlighted;
    var is_faded = is_filtered && is_highlighted || !is_hidden && highlight && !is_highlighted;
    return [is_hidden && 'is-hidden', is_faded && 'is-faded'].filter(Boolean).join(' ');
  }),
  lineClass: di(function (chart, d, i) {
    var transient_end_value = chart.transient_end_value();
    if (i === 0) {
      return "end-value-line ".concat(!!transient_end_value ? 'is-hidden' : '');
    } else {
      return "end-value-line is-transient".concat(!transient_end_value ? ' is-hidden' : '');
    }
  }),
  transform: function transform(data) {
    var end_value = this.end_value();
    var transient_end_value = this.transient_end_value();
    var filtered = this.filtered();
    var highlight = this.highlight();
    return filterOriginalData(data, end_value, {
      "transient": !!transient_end_value,
      transient_end_value: transient_end_value || this.__last_transient_value || end_value,
      filtered: filtered,
      highlight: highlight
    });
  }
});
export function filterOriginalData(original_data, end_value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _options$transient = options["transient"],
    _transient = _options$transient === void 0 ? false : _options$transient,
    _options$transient_en = options.transient_end_value,
    transient_end_value = _options$transient_en === void 0 ? null : _options$transient_en,
    _options$filtered = options.filtered,
    filtered = _options$filtered === void 0 ? [] : _options$filtered,
    _options$highlight = options.highlight,
    highlight = _options$highlight === void 0 ? null : _options$highlight;
  var original_values = original_data && original_data[0] && original_data[0].original_values;
  if (!original_values) return [];

  // Remove added transient data
  original_data = original_data.filter(function (series) {
    return !series.is_transient;
  });
  var end_value_series = original_data.map(toSeries(end_value, {
    hidden: _transient
  }));
  var transient_series = original_data.map(toSeries(transient_end_value, {
    hidden: !_transient,
    "transient": true
  }));
  return end_value_series.concat(transient_series);
  function toSeries(end_value, options) {
    var _options$hidden = options.hidden,
      hidden = _options$hidden === void 0 ? false : _options$hidden,
      _options$transient2 = options["transient"],
      _transient2 = _options$transient2 === void 0 ? false : _options$transient2;
    return function (series) {
      var key = withoutType(series.key);
      var is_filtered = filtered.length && includes(filtered, key) && (!highlight || highlight !== key);
      if (!series.original_class) series.original_class = series["class"];
      var is_transient = _transient2 ? ' is-transient' : '';
      var is_hidden = hidden ? ' is-hidden' : '';
      var class_name = "".concat(series.original_class).concat(is_transient).concat(is_hidden);
      var values = is_filtered ? [] : series.original_values.filter(function (point) {
        return end_value < 100 ? point.age === String(end_value) : point.year === end_value;
      });
      return assign({}, series, {
        key: _transient2 ? "".concat(series.key, "-transient") : series.key,
        values: values,
        "class": class_name,
        is_transient: _transient2
      });
    };
  }
}
export function withoutType(key) {
  // ...key-{type}-{reduction?}
  // where type = policy / baseline
  var parts = key.split('-').filter(function (part) {
    return part !== 'reduction' && part !== 'transient';
  });
  parts.pop();
  return parts.join('-');
}