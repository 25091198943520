import { Component } from 'backbone-component';
import { each } from 'lodash';
import App from '../app';
var ButtonComponent = Component.extend({
  tagName: 'button',
  template: App.template('button-component'),
  className: 'button-component btn btn-default',
  defaultProps: {
    onClick: function onClick() {},
    title: '',
    "class": '',
    data: {}
  },
  events: {
    click: 'handleClick'
  },
  initialize: function initialize() {
    var _this = this;
    this.listenTo(this.props, 'change:title', function () {
      if (_this.$el.attr('aria-describedby')) {
        _this.$el.tooltip('show');
      }
    });
  },
  render: function render() {
    var _this2 = this;
    Component.prototype.render.call(this);
    var _this$props$attribute = this.props.attributes,
      data = _this$props$attribute.data,
      title = _this$props$attribute.title;
    var className = this.props.get('class');
    this.$el.attr('class', 'button-component btn btn-default' + (className ? ' ' + className : ''));
    if (data) {
      each(data, function (value, key) {
        _this2.$el.attr("data-".concat(key), value);
      });
    }
    if (title) {
      this.$el.tooltip({
        title: function title() {
          return _this2.props.get('title');
        }
      });
    }
    return this;
  },
  handleClick: function handleClick(e) {
    var disabled = !!this.$el.attr('disabled') || this.$el.prop('disabled');
    if (disabled) return;
    this.props.get('onClick')(e);
  }
});
export default ButtonComponent;
ButtonComponent.registerAs('button');