import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = function format_percent(d) {
  return '$' + d3_format(',.2f')(d);
};
var default_NY_workplaces = format_percent(default_params.NY.original.ppp);
var default_MO_workplaces = format_percent(default_params.MO.original.ppp);
var TaxesTourView = TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Cigarette Taxes Policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      backdrop: true,
      element: '[data-id="taxes"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>The colors in this map represent the current Price Per Pack of cigarettes for each state. States that have low prices are colored darker while states that have higher prices colored lighter.</p>\n        <p>Hover your mouse pointer over the map to see the specific price per pack of cigarettes for each state."
    }, {
      backdrop: true,
      element: '[data-id="taxes"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>For example, in the state of New York a pack of cigarettes costs ".concat(default_NY_workplaces, ". States like New York have the <strong>least to gain</strong> from a Cigarette Taxes policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'NY'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="taxes"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>In contrast, in the state of Missouri a pack of cigarettes costs ".concat(default_MO_workplaces, ". States like Missouri with a low price per pack have the <strong>most to gain</strong> from a Cigarette Taxes policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'MO'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      element: '[data-id="taxes"] .policy-choropleth-container',
      placement: 'top',
      backdrop: true,
      title: 'How to use the tool',
      content: "<p>To determine the effects of raising the cigarette tax you start by clicking your mouse on the region (either a specific state or an average of all states) in the map. This will set the model parameters to match the current price for a pack of cigarettes in that region.</p>"
    }, {
      element: '#taxes-policy-year',
      placement: 'bottom',
      backdrop: true,
      title: 'Choose the policy start year',
      content: "<p>Then choose the start year of your new policy (default=".concat(default_policy_year, ").</p>")
    }, {
      element: '#taxes-increase',
      title: 'Choose proposed tax increase',
      placement: 'bottom',
      backdrop: true,
      content: "<p>Next, select your proposed tax increase from the dropdown menu.</p>"
    }, {
      element: '[data-id="taxes"] .taxes-edit-initial-region',
      title: 'Change initial price per pack',
      placement: 'bottom',
      backdrop: true,
      content: "<p>The initial price per pack is automatically set based on the current region/state. However you can override the initial price by clicking on the <svg class=\"icon-component\" style=\"margin-right: -10px;\"><use xlink:href=\"#icon-lock\" style=\"fill: #BBB;\"></use></svg> symbol</p>"
    }, {
      element: '#taxes-end-value',
      title: 'Choose horizon',
      backdrop: true,
      placement: 'bottom',
      content: "<p>Finally, choose the horizon of your new policy. The horizon is simply the end year you are interested in seeing results for. If you dont know just leave the start date and horizon as is.</p>"
    }, {
      element: '[data-id="taxes"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      backdrop: true,
      content: "\n        <p>A convenient summary of your policy is available below the policy parameters.</p>"
    }, {
      element: '[data-id="taxes"] #explorer-overview',
      title: 'Results',
      placement: 'top',
      backdrop: true,
      content: "<p>The values shown in the charts below are derived from simulations, using the Smoking History Generator population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention and Surveillance Modeling Network (CISNET)</a>.\n          This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p>\n          <p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the Cigarette Taxes policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});
export default TaxesTourView;