import { param } from 'jquery';
import { extend, each, indexOf, isArray, reduce, isUndefined } from 'lodash';
import * as Backbone from 'backbone';
var Router = Backbone.Router.extend({
  execute: function execute(callback, args) {
    // Replace querystring with parsed in args
    args.push(parseQuerystring(args.pop()));
    if (callback) {
      callback.apply(this, args);
    }
  },
  getQuerystring: function getQuerystring() {
    return parseQuerystring(urlParts().query);
  },
  setQuerystring: function setQuerystring(query, options) {
    var querystring = convertToQuerystring(query);
    var url = urlParts().hash + (querystring ? '?' + querystring : '');
    options = extend({
      trigger: false
    }, options);
    this.navigate(url, options);
  }
});
export default Router;
export function urlParts() {
  var parts = Backbone.history.getHash().split('?');
  return {
    hash: parts[0],
    query: parts[1]
  };
}
var EMPTY_ARRAY = '[none]';
export function parseQuerystring(querystring) {
  if (!querystring) return {};
  var query = {};
  var parts = querystring.split('&');
  each(parts, function (part) {
    var pair = part.split('=');
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    if (indexOf(key, '[') >= 0) applyNestedKey(key, value, query);else if (value == EMPTY_ARRAY) query[key] = [];else query[key] = value;
  });
  return query;
}
export function convertToQuerystring(query) {
  query = extend({}, query);
  each(query, function (value, key) {
    if (isArray(value) && !value.length) query[key] = EMPTY_ARRAY;
  });
  return decodeURIComponent(param(query));
}
export function applyNestedKey(key, value, query) {
  // a[0][0][]=3&a[0][0][]=4 -> [[[3,4]]]
  // a[0[0[ = 3 & a[0[0[ = 4
  // a, 0, 0, '' = 3,
  // ...
  // a: [[[3, 4]]]

  var indices = key.replace(/\]/g, '').split('[');
  var last_index = indices[indices.length - 1];

  // Initialize arrays/objects for indices
  var target = reduce(indices, function (memo, index, i) {
    // Skip key
    if (i === 0) return memo;

    // Check if parent is empty
    var prev_index = indices[i - 1];
    if (isUndefined(memo[prev_index])) {
      if (!index || i != indices.length && !isNaN(parseInt(prev_index))) {
        // Empty string = [] -> array
        // Numeric index = [Number] -> array
        // Caveat: For array, last index must be empty array
        //         so if last index is a number, it should still be an object
        memo[prev_index] = [];
      } else {
        // index is not a number, key for object
        memo[prev_index] = {};
      }
    }
    return memo[prev_index];
  }, query);
  if (!last_index) {
    // Empty string = [] -> push element into array
    target.push(value);
  } else {
    target[last_index] = value;
  }
  return query;
}