import { each, defaults } from 'lodash';
import { select } from 'd3';
import { Component } from 'backbone-component';
import App from '../app';
var default_margins = {
  top: 10,
  right: 15,
  bottom: 10,
  left: 10
};
var ChartComponent = Component.extend({
  template: App.template('chart-component'),
  defaultProps: {
    width: 600,
    height: 450,
    margins: {},
    "class": '',
    dirty: false
  },
  initialize: function initialize() {
    var _this = this;
    // Currently, only re-renders on data changes
    // may need to handle config changing while data stays the same, but not needed at-the-moment
    this.listenTo(this.props, 'change:data', this.render);
    this.listenTo(this.props, 'change:config', function () {
      if (_this.props.get('dirty')) {
        _this.props.set('dirty', false);
        _this.render();
      }
    });
    this.waiting = false;
  },
  render: function render() {
    var _this2 = this;
    Component.prototype.render.call(this);
    if (!this.chart) {
      var el = document.createElement('div');
      var margins = defaults(this.props.get('margins'), default_margins);
      this.chart = select(el).chart('Compose', function (data) {
        var config = _this2.props.get('config');
        var state = _this2.model && _this2.model.attributes;
        return config(data, state);
      }).margins(margins).width(this.props.get('width')).height(this.props.get('height'));
      if (this.props.get('on')) {
        each(this.props.get('on'), function (callback, key) {
          _this2.chart.on(key, callback);
        });
      }
      if (this.props.get('ref')) {
        this.props.get('ref')(this.chart);
      }
    }
    this.$el.removeClass().addClass(this.props.get('class'));
    this.$('[data-placeholder="chart"]').replaceWith(this.chart.container.node());
    var draw = function draw() {
      var deferred = _this2.waiting === true;
      _this2.waiting = false;
      Promise.resolve(_this2.props.get('data')).then(function (data) {
        data.__deferred = deferred;
        _this2.chart.draw(data);
      })["catch"](function (error) {
        return _this2.chart.draw({
          error: error
        });
      });
    };
    if (this.props.has('active') && !this.props.get('active')) {
      if (this.waiting) return this;
      this.waiting = true;
      this.listenToOnce(this.props, 'change:active', draw);
    } else {
      draw();
    }
    return this;
  }
});
export default ChartComponent;
ChartComponent.registerAs('chart');