import { bindAll, assign } from 'lodash';
import { View, observable, computed, bound, resolved, setValue } from 'backbone-component';
import config from '../config';
import App from '../app';
var noop = function noop() {};
var DetailsView = View.extend({
  template: App.template('details'),
  className: 'details-view',
  events: {
    'mouseenter [data-id="line"]': 'onInteractStart',
    'mouseenter [data-id="line-all"]': 'onInteractStart',
    'mouseleave .tcp-chart': 'onInteractEnd',
    'click [data-id="line"]': 'onChartClick',
    'click [data-id="line-all"]': 'onChartClick'
  },
  initialize: function initialize(options) {
    var _this = this;
    bindAll(this, 'attachChart', 'onEndValue', 'onFilter');
    this.data = options.data;
    this.details = options.details;
    this.onResetNAParameters = options.onResetParameters || noop;
    this.is_NA = options.is_NA || false;
    this.filtered = options.filtered || observable([]);
    this.state.connect({
      data: resolved(this.data),
      end_value: options.end_value,
      transient_end_value: options.transient_end_value,
      filtered: options.filtered
    });
    this.listenTo(this.model, 'change:stratification', function () {
      var _this$model$attribute = _this.model.attributes,
        stratification = _this$model$attribute.stratification,
        age_period = _this$model$attribute.age_period,
        age_group = _this$model$attribute.age_group;
      if (stratification === 'combined' && age_period === 'age') {
        _this.model.set('age_period', 'year');
      }
      if (stratification !== 'combined' && age_group !== '18-99') {
        _this.model.set('age_group', '18-99', {
          notify: true
        });
      }
    });
    this.lines = computed([bound(this.model), bound(this.state, 'end_value'), this.filtered], function (attributes, end_value, filtered) {
      var state = assign({
        end_value: end_value,
        filtered: filtered
      }, attributes);
      return options.lines(state);
    });
    this.listenTo(this.state, 'change:end_value', function () {
      if (!_this.state.get('active')) return;
      _this.chart.trigger('change:end_value', _this.state.get('end_value'));
    });
    this.listenTo(this.state, 'change:transient_end_value', function () {
      if (!_this.state.get('active')) return;
      _this.chart.trigger('change:transient_end_value', _this.state.get('transient_end_value'));
    });

    // Update charts in background on filter change
    this.listenTo(this.state, 'change:filtered', function () {
      if (!_this.chart || _this.state.get('active')) return;
      _this.chart.trigger('background:filter:series', _this.state.get('filtered'));
    });
    this.listenTo(this, 'activate', function () {
      _this.state.set('active', true);
    });
    this.listenTo(this, 'deactivate', function () {
      _this.state.set('active', false);
    });
    var is_small_screen = document.body.clientWidth < 500;
    this.chart_width = is_small_screen ? 400 : 600;
    this.chart_height = is_small_screen ? 300 : 375;
  },
  config: config,
  render: function render() {
    if (!this.state.get('active')) return this;
    if (this.state.get('data')) {
      View.prototype.render.call(this);
    } else {
      this.listenToOnce(this.state, 'change:data', this.render);
    }
    return this;
  },
  attachChart: function attachChart(chart) {
    this.chart = chart;
  },
  onInteractStart: function onInteractStart() {
    this.state.set({
      locked_end_value: false
    });
  },
  onInteractEnd: function onInteractEnd() {
    this.state.set({
      transient_end_value: null
    });
  },
  onChartClick: function onChartClick() {
    var is_locked = this.state.get('locked_end_value');
    if (!is_locked) {
      this.state.set({
        locked_end_value: true,
        end_value: this.state.get('transient_end_value'),
        transient_end_value: null
      }, {
        notify: true
      });
    } else {
      this.state.set({
        locked_end_value: false,
        transient_end_value: this.state.get('current_value')
      });
    }
  },
  onEndValue: function onEndValue(_ref) {
    var current_value = _ref.x;
    var values = {
      current_value: current_value
    };
    if (!this.state.get('locked_end_value')) {
      values.transient_end_value = current_value;
    }
    this.state.set(values);
  },
  onFilter: function onFilter(filtered) {
    setValue(this.filtered, filtered.slice());
  }
});
export default DetailsView;