import { filter, assign } from 'lodash';
var isNotPolicy = function isNotPolicy(series) {
  return series.key.indexOf('policy') === -1;
};
export default function getEndData(data) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$from_value = options.from_value,
    from_value = _options$from_value === void 0 ? false : _options$from_value;
  var end_data = filter(data, isNotPolicy).reverse().map(function (series) {
    var baseline_prop = series.meta.result_type;
    var policy_prop = baseline_prop.replace('baseline', 'policy');
    var values;
    if (from_value) {
      var point = series.values[0];
      var diff = point[baseline_prop] - point[policy_prop];
      var relative_diff = diff / point[baseline_prop];
      values = [assign({
        diff: diff,
        relative_diff: relative_diff,
        y_policy: point[policy_prop]
      }, point)];
    } else if (series.end_year_point) {
      var end_year_point = series.end_year_point;
      var _diff = end_year_point[baseline_prop] - end_year_point[policy_prop];
      var _relative_diff = _diff / end_year_point[baseline_prop];
      values = [assign({
        diff: _diff,
        relative_diff: _relative_diff,
        y_policy: end_year_point[policy_prop]
      }, end_year_point)];
    } else {
      values = [assign({
        diff: 0,
        relative_diff: 0,
        y_policy: 0,
        label: 'N/A'
      }, series.last_point, {
        y: 0
      })];
    }
    return assign({}, series, {
      values: values
    });
  });
  return end_data;
}