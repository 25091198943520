import { isArray, includes } from 'lodash';
import { Component } from 'backbone-component';
import { track } from '../analytics';
import { changer } from '../utils';
import App from '../app';
var defaultConvert = function defaultConvert(value) {
  return value;
};
var SelectComponent = Component.extend({
  tagName: 'select',
  template: App.template('select-component'),
  className: 'form-control inline-form-control select-component',
  defaultProps: {
    multiple: false,
    options: [],
    convert: defaultConvert,
    disabled: false,
    "class": ''
  },
  events: {
    change: 'handleChange',
    focus: 'handleFocus',
    blur: 'handleBlur'
  },
  initialize: function initialize() {
    var _this = this;
    this.focused = false;
    this.change = changer();
    this.listenTo(this.props, 'change:value', function (_1, _2, options) {
      _this.change(_this.render, _this);
      if (options && options.notify) {
        _this.flash();
      }
    });
    this.listenTo(this.props, 'change:options change:disabled', this.render);
  },
  render: function render() {
    Component.prototype.render.call(this);
    var className = this.props.get('class');
    this.$el.attr('class', 'form-control inline-form-control select-component' + (className ? ' ' + className : ''));
    this.$el.prop('multiple', this.props.get('multiple'));
    this.$el.attr('id', this.props.get('id'));
    this.$el.prop('disabled', this.props.get('disabled'));
    var value = this.props.get('value');
    var values = (isArray(value) ? value : [value]).map(String);
    this.$('option').each(function () {
      this.selected = includes(values, this.value);
    });
    if (this.focused) this.$el.focus();
    return this;
  },
  flash: function flash() {
    var _this2 = this;
    // Remove existing animation (forcing reflow)
    clearTimeout(this.fading);
    this.$el.removeClass('is-flashing');
    void this.el.offsetWidth;
    this.$el.addClass('is-flashing');
    this.fading = setTimeout(function () {
      return _this2.$el.removeClass('is-flashing');
    }, 3000);
  },
  handleChange: function handleChange() {
    var _this3 = this;
    // Include disabled + selected values and convert
    // (select value is string, get underlying value based on index)
    var options = this.props.get('options');
    var convert = this.props.get('convert');
    var value = this.$('option:selected').map(function () {
      var value = options[this.dataset.index] ? options[this.dataset.index].value : this.value;
      return convert(value);
    }).get();
    if (!this.props.get('multiple')) {
      value = value[0];
    }

    // TODO: add a converter to the props rather than hardcode
    if (value == 'true') value = true;else if (value == 'false') value = false;
    this.change(function () {
      return _this3.props.set('value', value);
    });
    track(this.props.get('id'), 'change', {
      label: value
    });
  },
  handleFocus: function handleFocus() {
    this.focused = true;
  },
  handleBlur: function handleBlur() {
    this.focused = false;
  },
  remove: function remove() {
    clearTimeout(this.fading);
  }
});
export default SelectComponent;
SelectComponent.registerAs('select');