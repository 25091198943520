import { Title, helpers } from 'd3-compose';
var translate = helpers.translate;
Title.extend('CenteredTitle', {
  update: function update() {
    Title.prototype.update.apply(this, arguments);

    // Use container width rather than layout value
    this.props.width = this.container.width();
  },
  setLayout: function setLayout(x, y, options) {
    var margins = this.margins();
    y += margins.top;
    this.base.attr('transform', translate(0, y));
    this.height(options && options.height);
    this.width(options && options.width);
  }
});