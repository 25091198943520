import IconComponent from './icon-component';
var InfoComponent = IconComponent.extend({
  className: 'icon-component info-component',
  defaultProps: {
    name: 'question-sign',
    title: '',
    placement: 'top'
  },
  render: function render() {
    IconComponent.prototype.render.call(this);
    var _this$props$attribute = this.props.attributes,
      title = _this$props$attribute.title,
      placement = _this$props$attribute.placement;
    this.$el.tooltip({
      title: title,
      placement: placement
    });
    return this;
  }
});
export default InfoComponent;
InfoComponent.registerAs('info');