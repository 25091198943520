function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { assign, every, defer, intersection, difference, each, includes } from 'lodash';
import { extent } from 'd3';
import { computed, resolved, bound, observable } from 'backbone-component';
import { deathsAvoidedQuery, prevalenceQuery, lysGainedQuery } from '../queries';
import getEndData from '../data/get-end-data';
import yearToAge, { ageToYear, policyYearToStartAge } from '../utils/year-to-age';
import getEndYearData from '../utils/get-end-year-data';
import config from '../config';
export function bindPrevalence(model, state, charts) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var explore_data = computed(filteredModel(model, 'filtered'), function (attributes) {
    return prevalenceQuery(attributes, options);
  });
  var explore_value = computed([resolved(explore_data), bound(model, 'age_period'), bound(state, 'end_value'), bound(state, 'transient_end_value')], function (data, age_period, end_value, transient_end_value) {
    if (!data) return;
    var stratification = model.get('stratification');
    var filter = _defineProperty({}, age_period, transient_end_value || end_value);
    var end_data = getEndData(getEndYearData(data, filter).values);
    var end_series = end_data.filter(function (series) {
      return stratification === 'all' || stratification === 'all-by-age' || series.key === 'both-single-baseline';
    });
    var end_values = end_series.map(function (series) {
      return series.values[0].both_policy;
    });
    return stratification === 'all' || stratification === 'all-by-age' ? extent(end_values) : end_values[0];
  });
  var explore_is_NA = false;
  return {
    charts: charts,
    options: options,
    overview_config: computed(model, function (attributes) {
      var choices = assign({
        end_value: state.get('end_value')
      }, attributes);
      return charts.overview(choices);
    }),
    explore_data: explore_data,
    explore_lines: charts.lines,
    explore_bars_end_value: charts.bars_end_value,
    explore_bars_end: charts.bars_end,
    explore_both_key: 'both-single-policy',
    explore_value: explore_value,
    explore_is_NA: explore_is_NA
  };
}
export function bindPrevalenceReduction(model, state, charts) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var explore_data = computed(filteredModel(model, 'filtered'), function (attributes) {
    return prevalenceQuery(attributes, options);
  });
  var explore_value = computed([resolved(explore_data), bound(model, 'age_period'), bound(state, 'end_value'), bound(state, 'transient_end_value')], function (data, age_period, end_value, transient_end_value) {
    if (!data) return;
    var stratification = model.get('stratification');
    var filter = _defineProperty({}, age_period, transient_end_value || end_value);
    var end_data = getEndData(getEndYearData(data, filter).values);
    var end_series = end_data.filter(function (series) {
      return stratification === 'all' || stratification === 'all-by-age' || series.key === 'both-single-baseline';
    });
    var end_values = end_series.map(function (series) {
      return series.values[0].both_baseline - series.values[0].both_policy;
    });
    return stratification === 'all' || stratification === 'all-by-age' ? extent(end_values) : end_values[0];
  });
  var explore_is_NA = false;
  return {
    charts: charts,
    options: options,
    overview_config: computed(model, function (attributes) {
      var choices = assign({
        end_value: state.get('end_value')
      }, attributes);
      return charts.overview(choices);
    }),
    explore_data: explore_data,
    explore_lines: charts.lines,
    explore_bars_end_value: charts.bars_end_value,
    explore_bars_end: charts.bars_end,
    explore_both_key: 'both-single-policy-reduction',
    explore_value: explore_value,
    explore_is_NA: explore_is_NA
  };
}
export function bindDeathsAvoided(model, state, charts) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var explore_data = computed(filteredModel(model, 'filtered'), function (attributes) {
    return deathsAvoidedQuery(attributes, options);
  });
  var explore_is_NA = computed(model, 'age_group', function (age_group) {
    return age_group !== '18-99';
  });
  var explore_value = computed([resolved(explore_data), bound(model, 'age_period'), bound(state, 'end_value'), bound(state, 'transient_end_value'), explore_is_NA], function (data, age_period, end_value, transient_end_value, is_NA) {
    if (!data) return;
    if (is_NA) return 'N/A';
    var stratification = model.get('stratification');
    var filter = _defineProperty({}, age_period, transient_end_value || end_value);
    var end_data = getEndData(getEndYearData(data, filter).values);
    var end_series = end_data.filter(function (series) {
      return stratification === 'all' || stratification === 'all-by-age' || series.key === 'both-single-deaths_avoided';
    });
    var end_values = end_series.map(function (series) {
      return series.values[0].deaths_avoided_both;
    });
    return stratification === 'all' || stratification === 'all-by-age' ? extent(end_values) : end_values[0];
  });
  return {
    charts: charts,
    options: options,
    overview_config: computed(model, function (attributes) {
      var choices = assign({
        end_value: state.get('end_value')
      }, attributes);
      return charts.overview(choices);
    }),
    explore_data: explore_data,
    explore_lines: charts.lines,
    explore_bars_end_value: charts.bars_end_value,
    explore_bars_end: charts.bars_end,
    explore_both_key: 'both-single-deaths_avoided',
    explore_value: explore_value,
    explore_is_NA: explore_is_NA
  };
}
export function bindLysGained(model, state, charts) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var explore_data = computed(filteredModel(model, 'filtered'), function (attributes) {
    return lysGainedQuery(attributes, options);
  });
  var explore_is_NA = computed(model, 'age_group', function (age_group) {
    return age_group !== '18-99';
  });
  var explore_value = computed([resolved(explore_data), bound(model, 'age_period'), bound(state, 'end_value'), bound(state, 'transient_end_value'), explore_is_NA], function (data, age_period, end_value, transient_end_value, is_NA) {
    if (!data) return;
    if (is_NA) return 'N/A';
    var stratification = model.get('stratification');
    var filter = _defineProperty({}, age_period, transient_end_value || end_value);
    var end_data = getEndData(getEndYearData(data, filter).values);
    var end_series = end_data.filter(function (series) {
      return stratification === 'all' || stratification === 'all-by-age' || series.key === 'both-single-lys_gained';
    });
    var end_values = end_series.map(function (series) {
      return series.values[0].lys_gained_both;
    });
    return stratification === 'all' || stratification === 'all-by-age' ? extent(end_values) : end_values[0];
  });
  return {
    charts: charts,
    options: options,
    overview_config: computed(model, function (attributes) {
      var choices = assign({
        end_value: state.get('end_value')
      }, attributes);
      return charts.overview(choices);
    }),
    explore_data: explore_data,
    explore_lines: charts.lines,
    explore_bars_end_value: charts.bars_end_value,
    explore_bars_end: charts.bars_end,
    explore_both_key: 'both-single-lys_gained',
    explore_value: explore_value,
    explore_is_NA: explore_is_NA
  };
}
export function bindWarnings(values) {
  var data = values.data,
    _values$flatline = values.flatline,
    flatline = _values$flatline === void 0 ? false : _values$flatline;
  var no_data = computed(resolved(data), function (data) {
    if (!data) return false;
    var has_data = data.length > 0 && data[0].values && data[0].values.length > 0;
    return !has_data;
  });
  return {
    flatline: flatline,
    no_data: no_data
  };
}
export function withStateDefaults(page, getDefaults) {
  var defaults = getDefaults(page.model.get('us_state') || 'US');
  page.defaults.set(defaults, {
    silent: true
  });
  page.model.set(defaults, {
    silent: true
  });
  page.listenTo(page.model, 'change:us_state', function () {
    var defaults = getDefaults(page.model.get('us_state') || 'US');
    page.defaults.set(defaults, {
      silent: true
    });
    page.model.set(defaults);
  });
}
export function withEditInitial(page, getDefaults) {
  if (getDefaults) {
    // With defaults, determine if initial load is in editing state
    //
    // This is a bit hacky:
    // 1. Registering activate here fires it before page so querystring hasn't been set yet
    // 2. Listen to model change for querystring set, but only in this event loop
    var defaults = getDefaults(page.model.get('us_state') || 'US');
    page.once('activate', function () {
      var onQuerystring = function onQuerystring() {
        var is_initial = every(Object.keys(defaults), function (key) {
          return page.model.get(key) == defaults[key];
        });
        page.state.set('editing_initial', !is_initial, {
          silent: true
        });
      };
      page.model.once('change', onQuerystring);
      defer(function () {
        return page.model.off('change', onQuerystring);
      });
    });
  }
  page.state.set('editing_initial', false);
  page.onEditInitial = function () {
    var was_editing = page.state.get('editing_initial');
    page.state.set('editing_initial', !was_editing);

    // Reset to defaults
    page.model.trigger('change:us_state');
  };
}
export function withEndValue(page) {
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2100;
  page.state.connect({
    end_value: max,
    end_value_min: computed([bound(page.model, 'age_period'), bound(page.model, 'policy_year')], function (age_period, policy_year) {
      return age_period === 'age' ? policyYearToStartAge(policy_year) : policy_year;
    }),
    end_value_max: computed(page.model, 'age_period', function (age_period) {
      return age_period === 'age' ? config.MAX_AGE : max;
    }),
    transient_end_value: null
  });
  page.listenTo(page.model, 'change:age_period', function () {
    var is_age = page.model.get('age_period') === 'age';
    var current_value = page.state.get('end_value');
    if (is_age && current_value > 1900) {
      page.state.set({
        end_value: yearToAge(current_value)
      }, {
        notify: true
      });
    } else if (!is_age && current_value < 1900) {
      page.state.set({
        end_value: ageToYear(current_value)
      }, {
        notify: true
      });
    }
  });
  page.listenTo(page.model, 'change:policy_year', function () {
    var is_age = page.model.get('age_period') === 'age';
    var policy_year = page.model.get('policy_year');
    var end_value = page.state.get('end_value');
    if (!is_age && end_value < policy_year) {
      page.state.set('end_value', policy_year, {
        notify: true
      });
    } else if (is_age && end_value < policyYearToStartAge(policy_year)) {
      page.state.set('end_value', policyYearToStartAge(policy_year), {
        notify: true
      });
    }
  });
}
export function withFiltered(page) {
  var group_filters = ['both-single', 'males-single', 'females-single'];
  var cohort_filters = ['both-1970', 'both-1980', 'both-1990', 'both-2000', 'both-2010', 'both-2020'];
  page.model.connect({
    filtered: []
  });
  page.listenTo(page.model, 'change:stratification', function () {
    var stratification = page.model.get('stratification');
    var filtered = page.model.get('filtered');
    var by_cohort = stratification === 'all' || stratification === 'all-by-age';

    // Reset filters on group / cohort change
    if (by_cohort && intersection(filtered, group_filters).length || !by_cohort && intersection(filtered, cohort_filters).length) {
      page.model.set('filtered', []);
    }
  });
}
export function withNotify(page, policy) {
  page.listenTo(page, 'notify', function (notifications) {
    var ids = notifications.map(function (key) {
      return "".concat(policy, "-").concat(key.replace('_', '-'));
    });
    each(page._components, function (component) {
      var component_id = component.props.get('id');
      if (!component_id || !includes(ids, component_id)) return;
      component.flash();
    });
  });
}
export function filteredModel(model) {
  var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (keys && !Array.isArray(keys)) keys = [keys];else if (!keys) keys = [];
  var filtered = observable(model.attributes);
  filtered.listenTo(model, 'change', function (_) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var changed = Object.keys(model.changed);
    if (!difference(changed, keys).length && !options.override) return;
    filtered.set(model.attributes);
  });
  return filtered;
}