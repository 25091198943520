function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { by_age_start_value, by_age_end_value, format, domains, age_group, initial_year_fix } from '../config';
import yearToAge from '../utils/year-to-age';
export function getDefaultPrevalence(choices) {
  var by_cohort = choices.stratification !== 'combined';
  var by_age = choices.age_period === 'age';
  var is_reduction = choices.mode === 'reduction';
  var is_all = choices.stratification === 'all' || choices.stratification === 'all-by-age';
  var is_single_cohort = !isNaN(choices.stratification);
  var both_key = is_reduction ? 'both-single-policy-reduction' : 'both-single-policy';
  var xScale = {
    domain: by_age ? [by_age_start_value, by_age_end_value] : [choices.policy_year - initial_year_fix, choices.horizon] // show the extra initial point
  };
  var label_filter = _defineProperty({}, choices.age_period, choices.end_value || xScale.domain[1]);
  var title = "".concat(is_reduction ? 'Prevalence reduction' : 'Smoking prevalence');
  var x_key = by_cohort ? choices.age_period : 'year';
  var y_key = is_reduction ? 'ydiff' : 'y';
  var y_tick_format = is_reduction ? format.pp2 : format.percent0;
  var y_axis_title = "".concat(is_reduction ? 'Prevalence reduction' : 'Smoking prevalence');
  var small_label_format = is_reduction ? format.pp1 : format.percent1;
  return {
    title: title,
    xScale: xScale,
    x_key: x_key,
    y_key: y_key,
    y_tick_format: y_tick_format,
    y_axis_title: y_axis_title,
    small_label_format: small_label_format,
    start_year: choices.policy_year,
    label_filter: label_filter,
    is_all: is_all,
    is_single_cohort: is_single_cohort,
    is_reduction: is_reduction,
    has_policy: true,
    both_key: is_all ? null : both_key,
    filtered: choices.filtered,
    show_start_value: !is_reduction,
    end_value: choices.end_value,
    chart_scaling: choices.chart_scaling
  };
}
export function getDefaultPrevalenceDetails(choices) {
  if (choices.by_cohort) {
    var is_reduction = choices.mode == 'reduction';
    return {
      title: (is_reduction ? 'Reduction in smoking prevalence ' : 'Smoking prevalence ') + ' by birth-cohort over ' + (choices.age_period == 'age' ? 'age' : 'calendar year')
    };
  } else {
    var _is_reduction = choices.mode == 'reduction';
    return {
      title: (_is_reduction ? 'Reduction in smoking prevalence ' : 'Smoking prevalence ') + 'for age group ' + format.ageGroup(choices.age_group)
    };
  }
}
export function getDefaultDeathsAvoided(choices) {
  var by_cohort = choices.stratification !== 'combined';
  var by_age = choices.age_period === 'age';
  var is_all = choices.stratification === 'all' || choices.stratification === 'all-by-age';
  var is_single_cohort = !isNaN(choices.stratification);
  var is_NA = choices.age_group !== '18-99';
  var xScale = {
    domain: by_age ? [by_age_start_value, by_age_end_value] : [choices.policy_year - initial_year_fix, choices.horizon] // show the extra initial point
  };
  var label_filter = _defineProperty({}, choices.age_period, choices.end_value || xScale.domain[1]);
  var x_key = by_cohort ? choices.age_period : 'year';
  return {
    title: 'Deaths avoided',
    xScale: xScale,
    x_key: x_key,
    y_tick_format: format.si2,
    y_axis_title: 'Deaths avoided',
    start_year: choices.policy_year,
    label_filter: label_filter,
    both_key: is_all ? null : 'both-single-deaths_avoided',
    small_label_format: format.si3,
    is_all: is_all,
    is_single_cohort: is_single_cohort,
    is_reduction: false,
    is_NA: is_NA,
    has_policy: false,
    filtered: choices.filtered,
    end_value: choices.end_value,
    chart_scaling: choices.chart_scaling
  };
}
export function getDefaultDeathsAvoidedDetails(choices) {
  if (choices.by_cohort) {
    return {
      title: 'Cumulative deaths avoided by birth-cohort over ' + (choices.age_period == 'age' ? 'age' : 'calendar year'),
      start_year: choices.policy_year
    };
  } else {
    return {
      title: 'Cumulative deaths avoided for age group ' + format.ageGroup(choices.age_group),
      start_year: choices.policy_year
    };
  }
}
export function getDefaultLysGained(choices) {
  var by_cohort = choices.stratification !== 'combined';
  var by_age = choices.age_period === 'age';
  var is_all = choices.stratification === 'all' || choices.stratification === 'all-by-age';
  var is_single_cohort = !isNaN(choices.stratification);
  var is_NA = choices.age_group !== '18-99';
  var xScale = {
    domain: by_age ? [by_age_start_value, by_age_end_value] : [choices.policy_year - initial_year_fix, choices.horizon] // show the extra initial point
  };
  var label_filter = _defineProperty({}, choices.age_period, choices.end_value || xScale.domain[1]);
  var x_key = by_cohort ? choices.age_period : 'year';
  return {
    title: 'Life-years gained',
    xScale: xScale,
    x_key: x_key,
    y_tick_format: format.si2,
    y_axis_title: 'Life-years gained',
    start_year: choices.policy_year,
    label_filter: label_filter,
    both_key: is_all ? null : 'both-single-lys_gained',
    small_label_format: format.si3,
    is_all: is_all,
    is_single_cohort: is_single_cohort,
    is_reduction: false,
    is_NA: is_NA,
    has_policy: false,
    filtered: choices.filtered,
    end_value: choices.end_value,
    chart_scaling: choices.chart_scaling
  };
}
export function getDefaultLysGainedDetails(choices) {
  if (choices.by_cohort) {
    return {
      title: 'Cumulative life-years gained by birth-cohort over ' + (choices.age_period == 'age' ? 'age' : 'calendar year'),
      start_year: choices.policy_year
    };
  } else {
    return {
      title: 'Cumulative life-years gained for age group ' + format.ageGroup(choices.age_group),
      start_year: choices.policy_year
    };
  }
}