import { bindAll, assign } from 'lodash';
import { scale as d3_scale, schemeGnBu } from 'd3';
import { Component, computed, bound, observable } from 'backbone-component';
import createChoroplethChart from '../charts/choropleth-chart';
import { original_params as states } from '../default-params';
import { changer } from '../utils';
import App from '../app';
var default_colors = schemeGnBu[8].slice(2).reverse();
var ChoroplethComponent = Component.extend({
  template: App.template('choropleth-component'),
  defaultProps: {
    options: {},
    us_state: 'US',
    faded: false,
    width: 950,
    height: 630
  },
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onStateClick');
    this.dirty = observable(false);
    var previous_state, previous_faded;
    this.config = computed([bound(this.props, 'options'), bound(this.props, 'us_state'), bound(this.props, 'faded')], function (options, us_state, faded) {
      // pass in options from /pages
      options = assign({
        colors: default_colors
      }, options); // override the defaults
      // everything else is for the tour
      // -----------------------------------

      var changed_faded_only = previous_state && us_state === previous_state && faded !== previous_faded;
      _this.dirty.set(changed_faded_only);
      previous_state = us_state;
      previous_faded = faded;
      if (faded && us_state !== 'US') {
        var grayscale_colors = ['#666', '#777', '#888', '#999', '#aaa', '#bbb'];
        if (options.colors.length < grayscale_colors.length) {
          // Issues occur if # of colors doesn't match grayscale
          while (grayscale_colors.length > options.colors.length) {
            grayscale_colors = grayscale_colors.slice(-(grayscale_colors.length - 1));
          }
        }
        var original = d3_scale.quantize().domain(options.domain).range(options.colors);
        var grayscale = d3_scale.quantize().domain(options.domain).range(grayscale_colors);
        var quantize = function quantize(value, id) {
          return id && id == us_state ? original(value) : grayscale(value);
        };
        quantize.domain = grayscale.domain;
        quantize.range = grayscale.range;
        options = assign({}, options, {
          quantize: quantize
        });
      }
      return createChoroplethChart(options);
    });
    this.data = computed(this.props, 'us_state', function (selected) {
      return {
        states: states,
        selected: selected
      };
    });
  },
  onStateClick: function onStateClick(selected) {
    if (this.props.get('options').can_click_states) {
      this.props.set('us_state', selected);
    }
  }
});
ChoroplethComponent.registerAs('choropleth');