import { isUndefined } from 'lodash';
import handlebars from 'handlebars'; // Handlebars functions need context
import { format as config_format } from './config';
import { getValue } from 'backbone-component';
function lowercase(str) {
  if (str && typeof str === 'string') {
    return str.toLowerCase();
  } else {
    return '';
  }
}
function format(value, style) {
  return config_format[style](getValue(value));
}
function loadingFormat(value, style) {
  if (isUndefined(getValue(value))) {
    return '...';
  } else if (getValue(value) === 'N/A') {
    return 'N/A';
  }
  return format(value, style);
}
function explorerFormat(value, style) {
  value = getValue(value);
  var format = config_format[style];
  if (!format) {
    throw new Error("No format \"".concat(style, "\" is defined in the config."));
  }
  if (isUndefined(value)) {
    return '...';
  } else if (value === 'N/A') {
    return 'N/A';
  } else if (Array.isArray(value)) {
    return "".concat(format(value[0]), " - ").concat(format(value[1]));
  }
  return format(value);
}
handlebars.registerHelper('lowercase', lowercase);
handlebars.registerHelper('format', format);
handlebars.registerHelper('loading-format', loadingFormat);
handlebars.registerHelper('explorer-format', explorerFormat);