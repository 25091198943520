function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { select, selectAll, max as d3_max } from 'd3';
import { isString, includes, find, assign } from 'lodash';
import { Component, Legend, helpers } from 'd3-compose';
import { removePolicyAndBaseline } from './end-year-lines';
import { svg as d3_svg } from 'd3';
var property = helpers.property,
  di = helpers.di,
  stack = helpers.stack,
  alignText = helpers.alignText,
  translate = helpers.translate,
  dimensions = helpers.dimensions;
var _Legend$prototype = Legend.prototype,
  swatchClass = _Legend$prototype.swatchClass,
  createSwatch = _Legend$prototype.createSwatch,
  getLegendData = _Legend$prototype.getLegendData;
var index = 0;
var TableLegend = Component.extend('TableLegend', {
  initialize: function initialize() {
    Legend.prototype.initialize.apply(this, arguments);
    this.layer('legend', this.base.classed('table-legend', true), {
      dataBind: function dataBind(data) {
        // Data comes in as table of rows then columns,
        // but for simpler highlighting behavior, arrange as columns then rows
        var asColumns = [];
        data.forEach(function (row) {
          row.forEach(function (column, j) {
            if (!asColumns[j]) {
              asColumns.push([]);
            }
            asColumns[j].push(column);
          });
        });
        return this.selectAll('.legend-column').data(asColumns, function () {
          return index++;
        });
      },
      insert: function insert() {
        var chart = this.chart();
        var columns = this.append('g').attr('class', 'legend-column').classed('mod-column-headers', function (d) {
          return isString(d[1]);
        });
        columns.append('rect').attr('class', 'legend-column-bg');
        var container = chart.container;
        var filterSeries = function filterSeries(active) {
          container.trigger('filter:series', chart.filtered(), active);
          var filtered_keys = chart.filtered().map(toLineKey);
          columns.classed('is-filtered', function (d) {
            if (!filtered_keys.length || isString(d[1])) return false;
            var key = removePolicyAndBaseline(d[1].key);
            return includes(filtered_keys, key);
          });
        };
        var isHeader = function isHeader(d) {
          return isString(d[1]);
        };
        var enterColumn = function enterColumn(d) {
          if (isHeader(d)) return;
          if (!chart.enabled()) return;
          var series = getSeriesData(d, container);
          container.trigger('enter:series', series, chart.filtered());
        };
        var leaveColumn = function leaveColumn(d) {
          if (isHeader(d)) return;
          if (!chart.enabled()) return;
          var series = getSeriesData(d, container);
          container.trigger('leave:series', series, chart.filtered());
        };
        var clickColumn = function clickColumn(d) {
          if (isHeader(d)) return;
          if (!chart.enabled()) return;
          var series = getSeriesData(d, container);
          var key = removePolicyAndBaseline(series.key);
          var filtered = chart.filtered().slice();
          var index = filtered.indexOf(key);
          var should_filter = index < 0;
          if (should_filter) filtered.push(key);else filtered.splice(index, 1);
          chart.filtered(filtered);
          filterSeries(key);
          if (!should_filter) enterColumn(d);
        };
        columns.on('mouseenter', enterColumn).on('mouseleave', leaveColumn).on('click', clickColumn);
        return columns;
      },
      events: {
        enter: function enter() {
          var chart = this.chart();
          var rows = this.selectAll('.legend-item').data(function (d) {
            return d;
          }, function (d, i) {
            return i;
          });
          //TODO: Extract series-type -> symbol information
          // into configuration file.
          var maleSymbol = d3_svg.symbol().type("triangle-up").size(25);
          var femaleSymbol = d3_svg.symbol().type("square").size(35);
          var defaultSymbol = d3_svg.symbol().type("circle").size(35);
          rows.enter().append('g').attr('class', 'legend-item').append(function (d, i) {
            // Based on item type, insert header, swatch, or spacer
            var el;
            if (isString(d) || !d) {
              // Header or Spacer
              el = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              select(el).attr('class', 'legend-header').text(d);
            } else {
              // Swatch
              el = document.createElementNS('http://www.w3.org/2000/svg', 'g');
              select(el).attr('class', "legend-swatch ".concat(d['class']));
              select(el).append('path').attr('class', 'chart-symbol').style('transform', function () {
                var dims = chart.swatchDimensions();
                return "translate(".concat(dims.width / 2, "px,").concat(dims.height / 2, "px)");
              }).attr('d', function () {
                // Only create a legend symbol for the last row
                if (i == chart.data().length - 1) {
                  switch (d.text) {
                    case "Males":
                      return maleSymbol(d);
                    case "Females":
                      return femaleSymbol(d);
                    default:
                      return defaultSymbol(d);
                  }
                }
              });
              chart.createSwatch.call(el, d, i);
            }
            return el;
          });
          this.call(alignTable, {
            row_padding: chart.rowPadding(),
            column_padding: chart.columnPadding(),
            has_column_headers: chart.data()[0][0] == null,
            dimensions: chart.swatchDimensions(),
            align: chart.align(),
            column_width: chart.columnWidth()
          });
        },
        merge: function merge() {
          var filtered = this.chart().filtered().map(toLineKey);
          this.classed('is-filtered', function (d) {
            if (isString(d[1])) return false;
            return includes(filtered, removePolicyAndBaseline(d[1].key));
          });
        },
        exit: function exit() {
          this.remove();
        }
      }
    });
  },
  setLayout: function setLayout(_, y) {
    Legend.prototype.setLayout.call(this, 0, y, {
      width: this.container.width()
    });
  },
  data: property(),
  swatchDimensions: property({
    default_value: {
      width: 20,
      height: 20
    }
  }),
  columnPadding: property({
    default_value: 5
  }),
  rowPadding: property({
    default_value: 5
  }),
  align: property({
    default_value: 'left'
  }),
  columnWidth: property(),
  filtered: property({
    default_value: []
  }),
  enabled: property({
    default_value: true
  }),
  swatchClass: di(function (chart, d, i) {
    return swatchClass.call(this, chart.options(), chart, d, i);
  }),
  createSwatch: di(function (chart, d, i) {
    return createSwatch.call(this, chart.options(), chart, d, i);
  })
}, {
  z_index: 200,
  swatches: Legend.swatches,
  getLegendData: getLegendData
});
export { TableLegend as default, getLegendData };
function allDescendents() {
  return this.childNodes;
}
export function descendants(type) {
  if (!type) {
    return allDescendents;
  }
  return function () {
    var children = this.childNodes;
    var filtered = [];
    for (var i = 0, l = children.length; i < l; i++) {
      if (children[i].nodeName == type) filtered.push(children[i]);
    }
    return filtered;
  };
}
export function alignTable(columns, options) {
  var row_padding = options.row_padding,
    column_padding = options.column_padding,
    has_column_headers = options.has_column_headers,
    dimensions = options.dimensions,
    align = options.align,
    column_width = options.column_width;
  var offsets = [];
  var sizes = [];

  // Align text to top
  columns.each(function (d, i) {
    offsets[i] = [];
    select(this).selectAll('text').attr('transform', function () {
      var offset = alignText(this);
      offsets[i].push(offset);
      return translate(0, offset);
    });
  });
  columns.each(function (d, i) {
    // Stack rows
    selectAll(descendants('g').call(this)).call(stack({
      min_height: dimensions.height,
      padding: row_padding
    }));

    // Get size for bbox
    sizes[i] = this.getBBox();
  });

  // Ignore first column if it contains headers
  var width = dimensions.width;
  if (column_width == 'max') {
    var width_sizes = has_column_headers ? sizes.slice(1) : sizes;
    width = d3_max(width_sizes, function (bbox) {
      return bbox.width;
    });
  } else if (column_width) {
    width = column_width;
  }
  columns.call(stack({
    direction: 'horizontal',
    min_width: width,
    padding: column_padding
  })).each(function (d, i) {
    var selection = select(this);
    selection.select('rect').attr('x', sizes[i].x - column_padding / 2).attr('y', sizes[i].y - column_padding / 2).attr('width', (column_width ? width : sizes[i].width) + column_padding).attr('height', sizes[i].height + column_padding + dimensions.height / 2);
    if (align == 'center') {
      var center_width = column_width ? width : sizes[i].width;
      if (!has_column_headers || i !== 0) {
        var header_offset = (center_width - sizes[i].width) / 2;
        selection.selectAll('.legend-header').attr('transform', function (d, j) {
          return translate(header_offset, offsets[i][j]);
        });
      }
      var swatch_offset = (center_width - dimensions.width) / 2;
      selection.selectAll('.legend-swatch').attr('transform', translate(swatch_offset, 0));
    }
  });
}
export function getSeriesData(d, container) {
  var key = d[1].key;
  var _key$split = key.split('.', 2),
    _key$split2 = _slicedToArray(_key$split, 2),
    chart_id = _key$split2[0],
    series_key = _key$split2[1];
  var is_reduction = series_key.indexOf('-reduction') >= 0;
  var chart_data = container.demux(chart_id, container.data());
  var line_data = chart_data;
  if (is_reduction) {
    // Matches getLineData in line-chart
    // (copied here to avoid circular dependency)
    line_data = chart_data.map(function (series) {
      if (series.key.indexOf('reduction') >= 0) return series;
      return assign({}, series, {
        key: series.key + '-reduction',
        "class": series['class'] + ' reduction'
      });
    });
  }
  var series = find(line_data, {
    key: series_key
  });
  return series;
}
function toLineKey(key) {
  return "line.".concat(key);
}