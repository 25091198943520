import { assign, bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { default_data_folder, default_policy_year, default_horizon } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withStateDefaults, withEditInitial, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import ExpenditureTourView from '../../views/expenditure-tour-view';
var getStateDefaults = function getStateDefaults(us_state) {
  var defaults = config.default_params[us_state];
  var new_policy_percent = 100;
  return {
    initial_percent: defaults.expenditure_percent_cdc,
    policy_percent: new_policy_percent
  };
};
export default PageView.extend({
  title: 'Tobacco Control Expenditures',
  template: App.template('expenditure'),
  defaults: function defaults() {
    return new Model({
      policy: 'expenditure',
      policy_year: default_policy_year,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_horizon,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: []
    });
  },
  conversions: {
    policy_year: Number,
    initial_percent: Number,
    policy_percent: Number
  },
  excludeFromQuerystring: ['by_cohort', 'age_period'],
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    withStateDefaults(this, getStateDefaults);
    withEditInitial(this, getStateDefaults);
    withEndValue(this);
    withFiltered(this);
    withNotify(this, 'expenditure');
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: computed(this.model, ['initial_percent', 'policy_percent'], function (initial_percent, policy_percent) {
        var us_state = _this.model.get('us_state');
        var us_state_label = _this.config.us_states[us_state].label;
        var defaults = getStateDefaults(us_state);
        var all_defaults_selected = defaults.initial_percent == initial_percent;
        var is_equal = initial_percent === policy_percent;
        var is_close = policy_percent - initial_percent <= 10;
        // TODO: review with Jamie, it appears that expenditures above 50% would have no effect on LYG but stating so, would essentially contradict the CDC recommendations. IOW, no reason to spend more than 50% of recommendation.
        if (defaults.initial_percent >= 80 && all_defaults_selected) {
          return "<p>".concat(us_state_label, " already has a strong expenditures policy in place that reduces smoking prevalence and smoking-related deaths. As such, improving expenditures coverage further would have little or no effect.</p>\n            <p>To explore further, click the <span class=\"icon-component icon-lock\"><svg><use xlink:href=\"#icon-lock\"></use></svg></span>icon to the left to unlock and change the default values.</p>");
        } else if (is_equal || is_close) {
          return "<p>The parameters you have chosen result in little or no effect on mortality or prevalence.</p>\n            <p><strong>Reason:</strong> It appears that initial expenditures value is too close to the proposed policy expenditures.</p>";
        }
      })
    });
    this.choropleth = charts.choropleth;
    this.tour = new ExpenditureTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
    this.listenTo(this.model, 'change:initial_percent', function () {
      var initial_percent = _this.model.get('initial_percent');
      var policy_percent = _this.model.get('policy_percent');

      // A policy percent choice can never be less than the initial percent
      if (initial_percent > policy_percent) {
        _this.model.set('policy_percent', initial_percent);
      }
    });
    this.policy_options = computed(this.model, 'initial_percent', function (initial_percent) {
      return config.expenditure_percents.map(function (option) {
        // In this case, we want to hide disabled options
        var disabled = option.value <= initial_percent && option.value != 100;
        return assign({
          hide: disabled,
          disabled: disabled
        }, option);
      });
    });
    this.cdc_amount = computed(this.model, 'us_state', function (us_state) {
      return config.us_states[us_state].expenditure_cdc * 1000000;
    });
    this.initial_amount = computed(this.model, ['us_state', 'initial_percent'], function (us_state, initial_percent) {
      return config.us_states[us_state].expenditure_cdc * 1000000 * initial_percent / 100;
    });
    this.policy_amount = computed(this.model, ['us_state', 'policy_percent'], function (us_state, policy_percent) {
      return config.us_states[us_state].expenditure_cdc * 1000000 * policy_percent / 100;
    });
  },
  config: config,
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default expenditures' : 'Click to change expenditures (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});