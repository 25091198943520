import { Component } from 'backbone-component';
import { changer } from '../utils';
import App from '../app';
var CheckboxComponent = Component.extend({
  tagName: 'label',
  className: 'control checkbox checkbox-component',
  template: App.template('checkbox-component'),
  events: {
    'change input': 'handleChange',
    'focus input': 'handleFocus',
    'blur input': 'handleBlur'
  },
  initialize: function initialize() {
    var _this = this;
    this.focused = false;
    this.change = changer();
    this.listenTo(this.props, 'change:checked', function () {
      _this.$('input').prop('checked', _this.props.get('checked'));
    });
    Object.defineProperty(this, 'labelledby', {
      get: function get() {
        return this.props.get('labelledby') || this.cid;
      }
    });
  },
  render: function render() {
    Component.prototype.render.call(this);
    if (this.focused) this.$('input').focus();
    return this;
  },
  handleChange: function handleChange() {
    var _this2 = this;
    this.change(function () {
      var value = _this2.$('input').prop('checked');
      _this2.props.set('checked', value);
    });
  },
  handleFocus: function handleFocus() {
    this.focused = true;
  },
  handleBlur: function handleBlur() {
    this.focused = false;
  }
});
export default CheckboxComponent;
CheckboxComponent.registerAs('checkbox');