import config from '../../js/config';
import { map, extend, first, last, find } from 'lodash';
export default function getEndYearData(data, value) {
  var filter = {};
  if (value.age) {
    filter.age = String(value.age);
  } else {
    filter.year = value.year;
  }
  var values = !data ? [] : map(data, function (series) {
    var year = series.meta.cohort;
    if (!isNaN(year)) {
      // +1 because cohort length is 99
      series.values = series.values.filter(function (d) {
        return d.year < Number(year) + config.cohort_length + 1;
      });
    }
    return extend({}, series, {
      first_point: first(series.values),
      last_point: last(series.values),
      end_year_point: find(series.values, filter)
    });
  });
  return {
    x: value.year || value.age,
    values: values
  };
}