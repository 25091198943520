import { includes, assign } from 'lodash';
import { helpers, Labels } from 'd3-compose';
import { filterOriginalData, withoutType } from './end-value-points';
var di = helpers.di,
  property = helpers.property,
  translate = helpers.translate;
Labels.extend('EndValueLabels', {
  initialize: function initialize(options) {
    var _this = this;
    this.base.classed('end-value-labels', true);
    this.__last_transient_value = null;
    Labels.prototype.initialize.call(this, options);
    var onEnterSeries = function onEnterSeries(series) {
      _this.highlight(withoutType(series.key));
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onLeaveSeries = function onLeaveSeries() {
      _this.highlight(null);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onFilterSeries = function onFilterSeries(filtered, active) {
      _this.filtered(filtered);
      if (_this.highlight() === active) {
        _this.highlight(null);
      }
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onChangeEndValue = function onChangeEndValue(end_value) {
      // For changing from by-year to by-age, want to skip redraw
      // (data-binding causes double draw, once for state, once for end-value)
      //
      // Check for end_value changing from > 1990 (year) to < 100 (age)
      _this.__last_transient_value = null;
      var last = _this.__last_end_value;
      _this.__last_end_value = end_value;
      var is_age = end_value < 100;
      var was_by_age = last < 100;
      if (last && is_age !== was_by_age) {
        return;
      }
      _this.end_value(end_value);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    var onTransientEndValue = function onTransientEndValue(end_value) {
      if (!end_value) {
        _this.__last_transient_value = _this.transient_end_value();
      }
      _this.transient_end_value(end_value);
      _this.__skip_transition = true;
      _this.draw(_this.data());
    };
    this.on('attach', function () {
      _this.container.on('enter:series', onEnterSeries);
      _this.container.on('leave:series', onLeaveSeries);
      _this.container.on('filter:series', onFilterSeries);
      _this.container.on('change:end_value', onChangeEndValue);
      _this.container.on('change:transient_end_value', onTransientEndValue);
    });
    this.on('detach', function () {
      _this.container.off('enter:series', onEnterSeries);
      _this.container.off('leave:series', onLeaveSeries);
      _this.container.off('filter:series', onFilterSeries);
      _this.container.off('change:end_value', onChangeEndValue);
      _this.container.off('change:transient_end_value', onTransientEndValue);
    });
  },
  offset: property({
    default_value: 10
  }),
  end_value: property(),
  transient_end_value: property(),
  highlight: property(),
  filtered: property({
    default_value: false
  }),
  labelClass: di(function (chart, d, i) {
    var highlight = chart.highlight();
    var filtered = chart.filtered();
    var key = withoutType(chart.seriesData.call(this, d, i).key);
    var is_highlighted = highlight === key;
    var is_filtered = filtered && includes(filtered, key);
    var is_hidden = is_filtered && !is_highlighted;
    var is_faded = is_filtered && is_highlighted || !is_hidden && highlight && !is_highlighted;
    return ['chart-label', 'small-label', d['class'], is_hidden && 'is-hidden', is_faded && 'is-faded'].filter(Boolean).join(' ');
  }),
  onMergeTransition: function onMergeTransition(selection) {
    // Don't fade-in on first draw or if not changed
    var values = this.data()[0].values;
    var last_value = values[values.length - 1];
    var y_value = last_value && last_value.y;
    var max_x = this.xScale().domain()[1];
    var changed = !last_value ? true : !this.__previous || this.__previous !== y_value || this.__previous_max !== max_x;
    if (this.drawn && changed && !this.__skip_transition) {
      this.setupTransition(selection);
    } else {
      selection.duration(0);
    }
    this.transitionLabels(selection);
    this.drawn = true;
    this.__previous = y_value;
    this.__previous_max = max_x;
    this.__skip_transition = false;
  },
  transform: function transform(data) {
    var end_value = this.end_value();
    var transient_end_value = this.transient_end_value();
    var filtered = this.filtered();
    var highlight = this.highlight();
    return filterOriginalData(data, end_value, {
      "transient": !!transient_end_value,
      transient_end_value: transient_end_value || this.__last_transient_value || end_value,
      filtered: filtered,
      highlight: highlight
    });
  },
  _handleCollisions: function _handleCollisions(chart, labels) {
    var grouped = {
      end_value: [],
      "transient": []
    };
    chart.data().forEach(function (series, index) {
      if (series && labels[index]) {
        var group = grouped[series.is_transient ? 'transient' : 'end_value'];
        group.push(labels[index]);
      }
    });
    Labels.prototype._handleCollisions.call(this, chart, grouped.end_value);
    Labels.prototype._handleCollisions.call(this, chart, grouped["transient"]);
  }
});