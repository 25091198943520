import { select } from 'd3';
import { Overlay, Legend, helpers } from 'd3-compose';
import { inverse } from './end-year-lines';
var property = helpers.property;
var createScale = helpers.createScale;
var swatches = Legend.swatches;
var SWATCH_WIDTH = 20;
var SWATCH_HEIGHT = 15;
Overlay.extend('TableLabels', {
  render: function render() {
    var labels = this.labels();
    this.base.classed('table-labels', true);
    if (!labels || !labels.length) {
      this.hide();
      this.showing(false);
      return;
    }

    // Set overlay position
    var chart = this.container.chartPosition();
    var xScale = createScale(this.xScale()).range([0, chart.width]);
    var yScale = createScale(this.yScale()).range([chart.height, 0]);
    var invert = this.invert();
    var offset = invert ? -this.offset() : this.offset();
    var x = inverse(xScale, xScale(this.x()) + offset);
    this.position({
      chart: {
        x: xScale(x),
        y: yScale(this.y())
      }
    });
    drawLabels(this.base, {
      labels: labels,
      invert: invert
    });
    this.show();
    this.showing(true);
  },
  draw: function draw() {},
  labels: property(),
  xScale: property(),
  yScale: property(),
  x: property(),
  y: property(),
  offset: property({
    default_value: 0
  }),
  invert: property({
    default_value: true
  }),
  showing: property({
    default_value: false
  })
});
function drawLabels(selection, props) {
  selection.classed('is-inverted', props.invert);
  if (props.labels.length && Array.isArray(props.labels[0])) drawTable(selection, props);else drawList(selection, props);
  drawArrow(selection, props);
}
function drawList(selection, props) {
  // Add labels list
  var list = selection.selectAll('.table-labels-list').data([null]);
  list.enter().append('ul').attr('class', 'table-labels-list');

  // Add labels items
  var items = list.selectAll('li').data(props.labels, key);

  // (exit)
  items.exit().remove();

  // (enter)
  items.enter().append('li').each(function (d) {
    var item = select(this);
    if (d.header) {
      item.append('span').attr('class', 'table-labels-header');
    } else {
      item.call(insertSwatch).append('span').attr('class', 'table-labels-label');
    }
  });

  // (merge)
  items.attr('class', function (d) {
    return 'table-labels-item' + (d['class'] ? ' ' + d['class'] : '');
  });
  items.select('span').text(function (d) {
    return d.text;
  });
}
function drawTable(selection, props) {
  // Add labels table
  var table = selection.selectAll('.table-labels-table').data([null]);
  table.enter().append('table').attr('class', 'table-labels-table');
  var rows = table.selectAll('tr').data(props.labels, function (d, i) {
    return i;
  });
  rows.exit().remove();
  rows.enter().append('tr');
  var cells = rows.selectAll('td,th').data(function (d) {
    return d;
  }, key);
  cells.exit().remove();
  cells.enter().insert(function (d) {
    return document.createElement(d.header ? 'th' : 'td');
  }).each(function (d) {
    if (!d.header) {
      var cell = select(this);
      if (d.type) cell.call(insertSwatch);
      cell.append('span').attr('class', 'table-labels-label');
    }
  });

  // (merge)
  cells.each(function (d) {
    var cell = select(this);
    cell.attr('class', 'table-labels-item' + (d['class'] ? ' ' + d['class'] : ''));
    if (d.header) cell.text(d.text).attr('colspan', d.colspan || null);else cell.select('span').text(d.text);
  });
}
function key(d, i) {
  // Include header and type in key to re-create if header or type changes
  return i + (d.header ? '-header' : '') + (d.type ? '-' + d.type : '');
}
function insertSwatch(selection) {
  selection.append('svg').attr('width', SWATCH_WIDTH).attr('height', SWATCH_HEIGHT).attr('class', 'table-labels-swatch').append('g').each(function (d, i) {
    var swatch = swatches[d.type] || swatches['default'];
    swatch.call(select(this), {
      swatchDimensions: function swatchDimensions() {
        return {
          width: SWATCH_WIDTH,
          height: SWATCH_HEIGHT
        };
      }
    }, d, i);
  });
}
function drawArrow(selection, props) {
  var arrow_path = props.invert ? 'M0,0 L 12,8 L 0,16' : 'M12,0 L 0,8 L 12,16';
  var arrow = selection.selectAll('.table-labels-arrow').data([null]);
  arrow.enter().append('svg').attr('width', 12).attr('height', 16).attr('class', 'table-labels-arrow').append('path');
  arrow.select('path').attr('d', arrow_path);
}