import { sortBy, map, keys, extend, mapValues } from 'lodash';
import original_params from './original-params.json';
export { original_params };
export function customRound(val) {
  var interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.25;
  // Rounds to nearest interval (0.25 by default)
  // maximum 100%
  return +(Math.round(Math.min(val, 1) * 1 / interval) * interval).toFixed(2) * 100;
}
export function customPPPRound(val) {
  // Round default prices to neareset 50 cents, and max at $11
  var round_to = 0.5;
  var rounded = round_to * Math.round(val / round_to); // round to nearest 0.50 cents
  return Math.min(Math.max(rounded, 4), 11);
}
export var default_params = mapValues(original_params, function (d) {
  return extend({}, {
    original: {
      ppp: +d.ppp,
      w: +d.w,
      w_law: +d.w_old,
      r: +d.r,
      b: +d.b,
      mla_pac19: +d.mla_pac19,
      mla_pac21: +d.mla_pac21,
      mla: +d.mla,
      expenditure_percent_cdc: +d.expenditure_percent_cdc
    },
    unrounded_ppp: d.ppp,
    ppp: customPPPRound(d.ppp),
    w_law: Math.round(d.w_old * 100),
    w_reported: Math.round(d.w * 100),
    w: customRound(d.w),
    r: customRound(d.r),
    b: customRound(d.b),
    expenditure_percent_cdc: customRound(d.expenditure_percent_cdc, 0.1),
    mla_pac19: customRound(d.mla_default_pac19),
    mla_pac21: customRound(d.mla_default_pac21)
  });
});
export var us_states = mapValues(original_params, function (d, key) {
  return {
    value: key,
    label: d.name,
    population: d.population,
    expenditure_cdc: d.expenditure_cdc
  };
});
export var us_states_array = sortBy(map(keys(us_states), function (key) {
  var row = us_states[key];
  row.id = key;
  return row;
}), function (row) {
  // order by state name, but put United States on top
  if (row.id == 'US') return ' ';else return row.label;
});