import { bindAll } from 'lodash';
import { Component } from 'backbone-component';
import { track } from '../analytics';
import App from '../app';
import deferred from '../utils/deferred';
var supports_after_print = ('onafterprint' in window);
var PolicyActionsComponent = Component.extend({
  className: 'header-buttons',
  template: App.template('policy-actions-component'),
  initialize: function initialize() {
    bindAll(this, 'onReadMore', 'onGettingStarted', 'onStartTour', 'onPrintPolicy', 'onAfterPrint');
    if (supports_after_print) {
      window.addEventListener('afterprint', this.onAfterPrint);
    }
  },
  onReadMore: function onReadMore() {
    $(this.props.get('info')).modal('toggle');
    track('read-more', 'show');
  },
  onGettingStarted: function onGettingStarted() {
    $(this.props.get('getting_started')).modal('toggle');
    track('getting-started', 'show');
  },
  onStartTour: function onStartTour() {
    this.props.set('touring', true);
    track('started-tour', 'show');
  },
  onPrintPolicy: function onPrintPolicy() {
    var _this = this;
    if (this.props.get('print')) {
      this.props.set('print', false);
      return;
    }
    window.printing = deferred();
    window.printing.then(function () {
      window.printing = null;
      window.print();
      if (!supports_after_print) {
        setTimeout(_this.onAfterPrint, 250);
      }
    });
    this.props.set('print', true);
    track('printed-policy', 'show');
  },
  onAfterPrint: function onAfterPrint() {
    this.props.set('print', false);
  },
  remove: function remove() {
    if (supports_after_print) {
      window.removeEventListener('afterprint', this.onAfterPrint);
    }
  }
});
export default PolicyActionsComponent;
PolicyActionsComponent.registerAs('policy-actions');