import { assign, find } from 'lodash';
import { format as d3_format, schemeGnBu } from 'd3';
import config, { getExpenditureLabelByValue, format, domains } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import App from '../../app';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
var progress_bar = App.template('progress-bar');
var bars_options = {
  x_domain: ['2000', '2010', '2020']
};
export var choropleth = {
  title: 'Percent covered by MLA 21',
  source: '',
  domain: [0, 1],
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    var progress = function progress(label, value) {
      var percent = Math.round(value * 100);
      var offset = percent < 30;
      var fill = stateFill(assign({}, state, {
        override: {
          mla_pac21: value
        }
      }));
      var color = offset ? '#000' : labelColor(assign({}, state, {
        override: {
          mla_pac21: value
        }
      }));
      return progress_bar({
        percent: percent,
        percent_text: "".concat(label, ": ").concat(format.percent0(value)),
        fill: fill,
        color: color,
        offset: offset
      });
    };
    return "Percent already covered (PAC) in 2017:<br>\n      ".concat(progress('PAC 19', d.mla_pac19), "\n      ").concat(progress('PAC 21', d.mla_pac21), "\n      <div class=\"hint\">\n        <strong>Hint:</strong> Click the state to set the initial PAC values (rounded to nearest 25%) and propose an MLA of 21.\n      </div>");
  },
  fillValue: function fillValue(d) {
    return d.mla_pac21;
  },
  legend_format: format.percent0,
  can_click_states: true,
  include_overlay_state_title: true
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].minage.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].minage.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].minage.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].minage.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].minage.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].minage.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].minage.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].minage.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
function getSubtitle(choices) {
  var mla = find(config.mla_ages, {
    value: choices.mla
  });
  if (mla) mla = mla.label;
  return "PAC 19=".concat(choices.mla_pac19, "%, PAC 21=").concat(choices.mla_pac21, "%, Minimum Age: 18\u2192").concat(mla);
}