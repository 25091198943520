import { isEqual, includes } from 'lodash';
import { chart as d3Chart, svg, select as d3_select } from 'd3';
import { helpers, mixins } from 'd3-compose';
import { removePolicyAndBaseline } from './end-year-lines';
var mixin = helpers.mixin;
var property = helpers.property;
var di = helpers.di;

/**
  Area chart

  @class Area
*/
var Mixed = mixin(d3Chart('Chart'), mixins.Series, mixins.XY, mixins.StandardLayer, mixins.Transition);
var id = 0;
Mixed.extend('Area', {
  initialize: function initialize() {
    var _this = this;
    Mixed.prototype.initialize.apply(this, arguments);
    this.standardSeriesLayer('Area', this.base.append('g').classed('chart-area', true));
    this.clip_id = 'area-clip-' + id++;
    var clip_path = this.base.append('defs').append('clipPath').attr('id', this.clip_id).append('rect').attr('x', 0).attr('y', 0);
    this.on('draw', function () {
      clip_path.attr('width', _this.width()).attr('height', _this.height());
    });
    var onEnterSeries = function onEnterSeries(series, filtered) {
      if (!_this.data() || !_this.data().length || !_this.data()[0].values.length) return;
      _this.filtered(filtered);
      var key = removePolicyAndBaseline(series.key);
      var filtered_keys = _this.filtered();
      var highlight = includes(filtered_keys, key);
      _this.base.selectAll('.chart-series').classed('is-faded', function (d) {
        // Highlight both policy and baseline
        var series_key = removePolicyAndBaseline(d.key);
        return highlight ? series_key === key : series_key != key && !includes(filtered_keys, series_key);
      });
    };
    var onLeaveSeries = function onLeaveSeries() {
      _this.base.selectAll('.chart-series').classed('is-faded', false);
    };
    var onFilterSeries = function onFilterSeries(filtered, active) {
      if (!_this.data() || !_this.data().length || !_this.data()[0].values.length) return;
      _this.filtered(filtered);
      var keys = _this.filtered();
      _this.base.selectAll('.chart-series').classed('is-faded', function (d) {
        return removePolicyAndBaseline(d.key) === removePolicyAndBaseline(active);
      }).classed('is-filtered', function (d) {
        return keys.length && includes(keys, removePolicyAndBaseline(d.key));
      });
    };
    this.on('attach', function () {
      _this.container.on('enter:series', onEnterSeries);
      _this.container.on('leave:series', onLeaveSeries);
      _this.container.on('filter:series', onFilterSeries);
    });
    this.on('detach', function () {
      _this.container.off('enter:series', onEnterSeries);
      _this.container.off('leave:series', onLeaveSeries);
      _this.container.off('filter:series', onFilterSeries);
    });
  },
  interpolate: property({
    default_value: 'monotone'
  }),
  filtered: property({
    default_value: []
  }),
  y0: di(function (chart, d) {
    return chart.yScale()(d.y0);
  }),
  y1: di(function (chart, d) {
    return chart.yScale()(d.y1);
  }),
  onDataBind: function onDataBind(selection, data) {
    return selection.selectAll('path').data(function (d, i, j) {
      return [data.call(selection, d, i, j)];
    });
  },
  onInsert: function onInsert(selection) {
    return selection.append('path').classed('chart-area', true).attr('clip-path', "url(#".concat(this.clip_id, ")"));
  },
  onMerge: function onMerge(selection) {
    // If x-domain has changed, draw new data with previous scale to prevent "tearing" in transition
    //
    // Note: don't update for "complete shift" (new max < old min or new min > old max)
    var previous_domain = this.previousXScale && this.previousXScale.domain();
    var current_domain = this.xScale().domain();
    var changed_scale = previous_domain && !isEqual(previous_domain, current_domain);
    var complete_shift = !previous_domain || current_domain[1] < previous_domain[0] || current_domain[0] > previous_domain[1];
    if (changed_scale && !complete_shift) {
      var _chart = this;
      var area = svg.area().x(function (d, i) {
        var value = _chart.xValue.call(this, d, i);
        return _chart.previousXScale(value);
      }).y0(function (d) {
        return _chart.previousYScale(d.y0);
      }).y1(function (d) {
        return _chart.previousYScale(d.y1);
      }).interpolate(this.interpolate());
      selection.attr('d', area);
    }
    this.previousXScale = this.xScale();
    this.previousYScale = this.yScale();
    var filtered = this.filtered();
    var chart = this;
    selection.each(function (d, i, j) {
      var parent = this.parentElement;
      var series = chart.seriesData.call(this, d, i, j);
      if (!parent || !series || !series.key) return false;
      var is_filtered = includes(filtered, removePolicyAndBaseline(series.key));
      d3_select(parent).classed('is-filtered', is_filtered);
    });
  },
  onMergeTransition: function onMergeTransition(selection) {
    this.setupTransition(selection);
    var area = svg.area().x(this.x).y0(this.y0).y1(this.y1).interpolate(this.interpolate());
    selection.attr('d', area).attr('style', this.itemStyle);
  }
});