import { Component } from 'backbone-component';
import App from '../app';
var ShowComponent = Component.extend({
  template: App.template('show-component'),
  defaultProps: {
    show: false
  },
  initialize: function initialize() {
    this.listenTo(this.props, 'change:show', this.toggle);
  },
  render: function render() {
    Component.prototype.render.call(this);
    this.toggle();
    return this;
  },
  toggle: function toggle() {
    this.$el.prop('hidden', this.props.get('show'));
  }
});
export default ShowComponent;
ShowComponent.registerAs('show');