import { extend, isObject } from 'lodash';
import { Component } from 'backbone-component';
import App from '../app';
var TableComponent = Component.extend({
  tagName: 'table',
  className: 'table',
  template: App.template('table-component'),
  defaultProps: {
    hover: false,
    data: {
      head: [],
      body: []
    }
  },
  initialize: function initialize() {
    this.head = [];
    this.body = [];
    this.listenTo(this.props, 'change:data', this.render);
  },
  render: function render() {
    var _this = this;
    this.$el.toggleClass('table-hover', this.props.hover);
    Promise.resolve(this.props.get('data')).then(function () {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _data$head = data.head,
        head = _data$head === void 0 ? [] : _data$head,
        _data$body = data.body,
        body = _data$body === void 0 ? [] : _data$body;
      _this.head = toFormatted(head, {
        header: true
      });
      _this.body = toFormatted(body);
      Component.prototype.render.call(_this);
    });
    return this;
  }
});
TableComponent.registerAs('table');
function toFormatted(rows) {
  var defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return rows.map(function (row) {
    return row.map(function (column) {
      if (!isObject(column)) {
        column = {
          text: column
        };
      }
      return extend({}, defaults, column);
    });
  });
}