import Router from './lib/router';
import App from './app';
import { track, setFields } from './analytics';
var AppRouter = Router.extend({
  routes: {
    '(:id)': 'page'
  },
  page: function page() {
    var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'home';
    if (id == null) id = 'home';
    App.log.info("[Router] page(\"".concat(id, "\")"));
    App.view.setActive(id);
    track('pageview', id);
    setFields({
      page: id
    });
  },
  navigate: function navigate(url) {
    App.log.info("[Router] Navigate: ".concat(url));
    Router.prototype.navigate.apply(this, arguments);
  }
});
export default AppRouter;