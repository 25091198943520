import { extend } from 'lodash';
import * as Backbone from 'backbone';
import Logger from './logger';
export default function Application(options) {
  this.log = new Logger();
  this.log.enabled = true;
  this.log.level = 'ERROR';
  this.templates = options && options.templates;
  this.initialize(options);
}
extend(Application.prototype, Backbone.Events, {
  initialize: function initialize() {},
  startup: function startup() {
    if (!this.started) {
      if (!Backbone.history.started) {
        Backbone.history.start();
      }
      this.log.info('[Application] Started');
      this.started = true;
    }
  },
  template: function template(name) {
    var template = this.templates && this.templates[name];
    if (!template) {
      this.log.error("Unable to find template: \"".concat(name, "\""));
      return function () {
        return '';
      };
    } else {
      return template;
    }
  }
});
Application.extend = Backbone.View.extend;