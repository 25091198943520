/* global ENV */
import SHGPOL from 'SHGPOL';
import { on } from 'rsvp';
import { extend } from 'lodash';
import Application from './lib/application';
var SHGApplication = Application.extend({
  initialize: function initialize() {
    Application.prototype.initialize.call(this);
    this.info = extend({
      is_first_visit: true,
      has_viewed_tour: false
    }, localStorage.info && JSON.parse(localStorage.info));
    localStorage.info = JSON.stringify({
      is_first_visit: false
    });
  },
  shouldShowTour: function shouldShowTour() {
    return this.info.is_first_visit && !this.has_viewed_tour;
  },
  shownTour: function shownTour() {
    this.has_viewed_tour = true;
  }
});
var App = new SHGApplication({
  templates: SHGPOL.templates
});

// Configure App
SHGPOL.App = App;
App.log.enabled = true;
App.log.level = ENV.development ? 'INFO' : 'ERROR';

// Log unhandled Promise errors
on('error', function (err) {
  return App.log.error(err);
});
export default App;