import { bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { default_data_folder, default_policy_year_ghw, default_horizon, format } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import HealthTourView from '../../views/health-tour.view'; // TODO: create ghw tour

export default PageView.extend({
  title: 'Graphic health warnings',
  template: App.template('health'),
  defaults: function defaults() {
    return new Model({
      policy: 'health',
      policy_year: default_policy_year_ghw,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_horizon,
      initiation: 0.1,
      cessation: 0.5,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: []
    });
  },
  excludeFromQuerystring: ['unrounded_price', 'default_price', 'by_cohort', 'age_period'],
  initialize: function initialize() {
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    withEndValue(this);
    withFiltered(this);
    withNotify(this, 'health');
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: false
    });
    this.choropleth_options = charts.choropleth;
    this.tour = new HealthTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
  },
  config: config,
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default current price' : 'Click to change current price (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});