import $ from 'jquery';
import { defer, bindAll } from 'lodash';
import { Component, computed, bound } from 'backbone-component';
import SliderComponent from '../components/slider-component';
import App from '../app';
var RangeDropdownComponent = Component.extend({
  template: App.template('range-dropdown-component'),
  className: 'range-dropdown dropdown dropup',
  events: {
    'shown.bs.dropdown': 'onShown',
    'hide.bs.dropdown': 'onHide',
    'mouseenter .dropdown-menu': 'onInteract',
    'mouseleave .dropdown-menu': 'onStopInteract',
    'blur .slider-handle': 'onBlur',
    'click .js-five-year': 'onFiveYear'
  },
  defaultProps: {
    value: 0,
    min: 0,
    max: 100,
    step: 1,
    onSlideStart: function onSlideStart() {},
    onSlideStop: function onSlideStop() {},
    disabled: false
  },
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onFiveYear');
    this.choices = computed(this.props, ['min', 'max'], function (min, max) {
      return [{
        value: min,
        label: String(min)
      }, {
        value: max,
        label: String(max)
      }];
    });
    this.listenTo(this.props, 'change:disabled', function () {
      _this.$('.dropdown-toggle').attr('disabled', _this.props.get('disabled') ? 'disabled' : null);
    });
    this.listenTo(this.props, 'change:value', function (_1, _2, options) {
      if (options && options.notify) {
        _this.flash();
      }
    });
    this.showing = false;
  },
  render: function render() {
    Component.prototype.render.call(this);
    this.$('.dropdown-toggle').attr('disabled', this.props.get('disabled') ? 'disabled' : null);
    return this;
  },
  hide: function hide() {
    if (this.showing) {
      this.$('button').dropdown('toggle');
    }
  },
  flash: function flash() {
    var button = this.$('.dropdown-toggle');

    // Cancel any running animation
    clearTimeout(this.fading);
    if (button.hasClass('is-flashing')) {
      // (remove class and force reflow before reapplying animation)
      button.removeClass('is-flashing');
      void button.get(0).offsetWidth;
    }
    button.addClass('is-flashing');
    this.fading = setTimeout(function () {
      return button.removeClass('is-flashing');
    }, 3000);
  },
  onShown: function onShown() {
    var _this2 = this;
    this.showing = true;
    if (!this.slider) {
      this.slider = new SliderComponent({
        props: {
          value: bound(this.props, 'value'),
          choices: this.choices,
          onSlideStart: computed(this.props, 'onSlideStart', function (fn) {
            return function () {
              _this2.interacting = true;
              if (fn) fn();
            };
          }),
          onSlideStop: computed(this.props, 'onSlideStop', function (fn) {
            return function () {
              defer(function () {
                _this2.interacting = false;
              });
              if (fn) fn();
            };
          })
        }
      });
      this.$('[data-placeholder="slider"]').replaceWith(this.slider.el);
      this.slider.render();
    } else {
      this.slider.render();
    }
    this.$('.slider-handle').focus();
  },
  onHide: function onHide(e) {
    // Stop hide if interacting with slider
    if (this.interacting) {
      e.preventDefault();
      return;
    }
    this.showing = false;
    clearTimeout(this.auto_close);
  },
  onInteract: function onInteract() {
    clearTimeout(this.auto_close);
  },
  onStopInteract: function onStopInteract() {
    var _this3 = this;
    clearTimeout(this.auto_close);
    this.auto_close = setTimeout(function () {
      _this3.hide();
    }, 2000);
  },
  onBlur: function onBlur(e) {
    var related = $(e.relatedTarget);
    var inside_dropdown = related.parent('.range-dropdown-menu').length > 0;
    if (!inside_dropdown) {
      this.hide();
    }
  },
  onFiveYear: function onFiveYear() {
    this.props.set('value', this.props.get('min') + 4); // TODO review: + 4 (and not + 5) because min value is already end of 1st year
  },
  remove: function remove() {
    $(window).off('resize', this.onResize);
    clearTimeout(this.fading);
  }
});
export default RangeDropdownComponent;
RangeDropdownComponent.registerAs('range-dropdown');