import { bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { default_data_folder, default_policy_year, default_minage_horizon } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withStateDefaults, withEditInitial, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import ExpenditureTourView from '../../views/minage-tour-view';
import { changer } from '../../utils';
var getStateDefaults = function getStateDefaults(us_state) {
  var defaults = config.default_params[us_state];
  return {
    mla_pac19: defaults.mla_pac19,
    mla_pac21: defaults.mla_pac21,
    mla: 1 // 1=21
  };
};
export default PageView.extend({
  title: 'Minimum Age of Legal Access (MLA)',
  template: App.template('minage'),
  defaults: function defaults() {
    return new Model({
      policy: 'minage',
      policy_year: default_policy_year,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_minage_horizon,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: []
    });
  },
  conversions: {
    policy_year: Number,
    mla_pac19: Number,
    mla_pac21: Number,
    mla: Number
  },
  excludeFromQuerystring: ['by_cohort', 'age_period'],
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    this.stratification_types = [{
      value: 'combined',
      label: 'Population',
      cohorts: ['ALL']
    }, {
      value: '2000',
      label: '2000 birth-cohort',
      cohorts: ['2000']
    }, {
      value: '2010',
      label: '2010 birth-cohort',
      cohorts: ['2010']
    }, {
      value: '2020',
      label: '2020 birth-cohort',
      cohorts: ['2020']
    }, {
      value: 'all',
      label: 'All cohorts',
      cohorts: ['2000', '2010', '2020']
    }, {
      value: 'all-by-age',
      label: 'All cohorts by age',
      cohorts: ['2000', '2010', '2020']
    }];
    withStateDefaults(this, getStateDefaults);
    withEditInitial(this, getStateDefaults);
    withEndValue(this, 2100);
    withFiltered(this);
    withNotify(this, 'minage');
    var query_options = {
      stratification_types: this.stratification_types
    };
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence, query_options);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction, query_options);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided, query_options);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained, query_options);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: computed(this.model, ['mla_pac19', 'mla_pac21', 'mla' // 0=19, 1=21, 2=25
      ], function (mla_pac19, mla_pac21, mla) {
        var us_state = _this.model.get('us_state');
        var us_state_label = _this.config.us_states[us_state].label;
        var defaults = getStateDefaults(us_state);
        var standard = function standard(reason) {
          return "<p>The parameters you have chosen result in little or no effect on mortality or prevalence.</p>\n          <p><strong>Reason:</strong> ".concat(reason, "</p>");
        };
        var all_defaults_selected = defaults.mla_pac21 == mla_pac21 && defaults.mla_pac19 == mla_pac19 && defaults.mla == mla;

        // TODO review text and logic. Just stubbing this in for initial UAT
        if (defaults.mla_pac21 == 100 && (defaults.mla === 0 || defaults.mla === 1) && all_defaults_selected) {
          return "<p>".concat(us_state_label, " already has a strong MLA policy in place that reduces smoking prevalence and smoking-related deaths. As such, improving MLA coverage further would have little or no effect.</p>\n            <p>To explore further, click the <span class=\"icon-component icon-lock\"><svg><use xlink:href=\"#icon-lock\"></use></svg></span>icons to the left to unlock and change the default values.</p>");
        } else if (mla_pac21 == 100 && (mla == 0 || mla == 1)) {
          return standard("It appears that PAC 21 is 100%, but your proposed policy only increases the MLA to 19 or 21 which would have no effect.");
        } else if (mla_pac19 + mla_pac21 == 100 && mla == 0) {
          return standard("It appears that PAC 19 + PAC 21 = 100%, but your proposed policy only increases the MLA to 19 which would have no effect.");
        }
      })
    });
    this.choropleth = charts.choropleth;
    this.tour = new ExpenditureTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
    this.change = changer();
    this.listenTo(this.model, 'change:mla_pac19', function () {
      _this.change(function () {
        var mla_pac19 = _this.model.get('mla_pac19');
        var mla_pac21 = _this.model.get('mla_pac21');
        if (mla_pac19 + mla_pac21 > 100) {
          _this.model.set('mla_pac21', 100 - mla_pac19, {
            notify: true
          });
        }
      });
    });
    this.listenTo(this.model, 'change:mla_pac21', function () {
      _this.change(function () {
        var mla_pac19 = _this.model.get('mla_pac19');
        var mla_pac21 = _this.model.get('mla_pac21');
        if (mla_pac19 + mla_pac21 > 100) {
          _this.model.set('mla_pac19', 100 - mla_pac21, {
            notify: true
          });
        }
      });
    });
    this.listenTo(this.model, 'change:initial_percent', function () {
      var initial_percent = _this.model.get('initial_percent');
      var policy_percent = _this.model.get('policy_percent');

      // A policy percent choice can never be less than the initial percent
      if (initial_percent > policy_percent) {
        _this.model.set('policy_percent', initial_percent);
      }
    });
  },
  config: config,
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default minimum age' : 'Click to change minimum age (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});