import { Lines } from 'd3-compose';
import { interpolatePath } from 'd3-interpolate-path';
Lines.extend('TransitionLines', {
  onMergeTransition: function onMergeTransition(selection) {
    var chart = this;
    this.setupTransition(selection);
    selection.attr('style', this.itemStyle).attrTween('d', function (d, i) {
      var previous = d3.select(this).attr('d');
      var current = chart.lineData.call(this, d, i);

      // Skip transition on first draw
      return interpolatePath(previous || current, current);
    });
  }
});