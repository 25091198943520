function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { assign, random } from 'lodash';
import { format as d3_format } from 'd3';
import { format, domains } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
export var choropleth = {
  policy: 'ghw',
  title: 'Graphic health warnings implemented in the US in 2022',
  // TODO: give it a better title
  //TODO: Look into what year the title will refer to and the range of domains
  source: '',
  domain: [0, 1],
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    return "\n      <h3>Effective Date of Final Rule</h3>\n      <div class=\"hint\">\n          The U.S. District Court for the Eastern District of Texas issued an order to postpone \n          the effective date of the \u201CRequired Warnings for Cigarette Packages and Advertisements\u201D \n          until <b>Oct. 11, 2022.</b>\n      </div>\n      <br />\n      <img class='fda-warning' src='img/g0".concat(random(1, 3), ".jpg' />\n      ");
  },
  fillValue: function fillValue(d) {
    return 0;
  },
  // the color of the states
  legend_format: d3_format('d'),
  has_legend: false,
  can_hover: true,
  can_click_states: false,
  include_overlay_state_title: false
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, _defineProperty({
      yScale: yScale,
      subtitle: subtitle
    }, "subtitle", subtitle)));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].healthwarnings.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
function getSubtitle(choices) {
  var cohort_text = '';
  if (choices.by_cohort && choices.stratification != 'all' && choices.stratification != 'all-by-age') {
    cohort_text = "".concat(choices.stratification, " birth-cohort | ");
  }
  return "".concat(cohort_text, "Smoking initiation reduction: ").concat(format.percent0(choices.initiation), "\n | Smoking cessation increase : ").concat(format.percent0(choices.cessation));
}