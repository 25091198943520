import { Chart, helpers } from 'd3-compose';
var property = helpers.property,
  translate = helpers.translate;
Chart.extend('OverlayText', {
  initialize: function initialize() {
    this.layer('text', this.base.classed('overlay-text', true), {
      dataBind: function dataBind() {
        return this.selectAll('text').data([null]);
      },
      insert: function insert() {
        return this.append('text');
      },
      events: {
        merge: function merge() {
          var chart = this.chart();
          this.text(chart.text()).attr('text-anchor', 'middle').attr('transform', function () {
            var offset = -34;
            var x = chart.width() / 2;
            var y = chart.height() / 2 + offset;
            return translate(x, y);
          });
        }
      }
    });
  },
  text: property()
});