import { Component } from 'backbone-component';
import App from '../app';
var IconComponent = Component.extend({
  tagName: 'span',
  template: App.template('icon-component'),
  defaultProps: {
    name: '',
    white: false
  },
  render: function render() {
    Component.prototype.render.call(this);
    var _this$props$attribute = this.props.attributes,
      name = _this$props$attribute.name,
      white = _this$props$attribute.white;
    this.$el.attr('class', "icon-component icon-".concat(name).concat(white ? ' color-white' : ''));
  }
}, {
  render: function render(props) {
    var instance = new this({
      props: props
    });
    instance.render();
    return instance.el.outerHTML;
  }
});
export default IconComponent;
IconComponent.registerAs('icon');