import { map } from 'lodash';
import { Component } from 'backbone-component';
import { changer } from '../utils';
import App from '../app';
import { track } from '../analytics';
var noop = function noop() {};
var SliderComponent = Component.extend({
  template: App.template('slider-component'),
  className: 'slider-component',
  defaultProps: {
    tooltip: 'hide',
    selection: 'before',
    hideLabels: false,
    labelsAbove: false,
    formatter: function formatter(value) {
      return value;
    },
    minDisabled: false,
    onSlideStart: noop,
    onSlideStop: noop
  },
  events: {
    change: 'handleChange'
  },
  initialize: function initialize() {
    var _this = this;
    this.change = changer();
    this.listenTo(this.props, 'change:value', function () {
      var value = _this.props.get('value');
      _this.change(function () {
        _this.$('input').val(value).slider('setValue', value);
      });
    });
    this.listenTo(this.props, 'change:choices', this.render);
    this.listenTo(this.props, 'change:selection', function () {
      var selection = _this.props.get('selection');
      _this.$('input').slider('setAttribute', 'selection', selection);
      _this.refresh();
    });
    this.listenTo(this.props, 'change:rangeHighlights', function () {
      var highlights = _this.props.get('rangeHighlights');
      _this.$('input').slider('setAttribute', 'rangeHighlights', highlights);
      _this.refresh();
    });
    this.listenTo(this.props, 'change:minDisabled', function () {
      var minDisabled = _this.props.get('minDisabled');
      _this.$('input').slider('setAttribute', 'minDisabled', minDisabled);
      _this.refresh();
    });
  },
  render: function render() {
    Component.prototype.render.call(this);
    var _this$props$attribute = this.props.attributes,
      step = _this$props$attribute.step,
      formatter = _this$props$attribute.formatter,
      tooltip = _this$props$attribute.tooltip,
      labelsAbove = _this$props$attribute.labelsAbove,
      minDisabled = _this$props$attribute.minDisabled,
      value = _this$props$attribute.value,
      choices = _this$props$attribute.choices,
      selection = _this$props$attribute.selection,
      rangeHighlights = _this$props$attribute.rangeHighlights,
      tooltip_position = _this$props$attribute.tooltipPosition,
      onSlideStart = _this$props$attribute.onSlideStart,
      onSlideStop = _this$props$attribute.onSlideStop;
    var ticks = choices && map(choices, 'value');
    var ticks_positions = choices && 'position' in choices[0] ? map(choices, 'position') : null;
    var ticks_labels = choices && !this.props.hideLabels ? map(choices, 'label') : false;
    var range = Array.isArray(value);
    var tooltip_split = range;
    this.$el.toggleClass('mod-labels-above', labelsAbove);
    this.$el.toggleClass('before', selection == 'before');
    this.$el.toggleClass('after', selection == 'after');
    this.$('input').attr('value', value).slider({
      value: value,
      range: range,
      ticks: ticks,
      ticks_snap_bounds: 1,
      ticks_labels: ticks_labels,
      ticks_positions: ticks_positions,
      tooltip: tooltip,
      tooltip_position: tooltip_position,
      tooltip_split: tooltip_split,
      step: step,
      formatter: formatter,
      selection: selection,
      rangeHighlights: rangeHighlights,
      htmlTooltips: true,
      slideOnTooltip: false,
      minDisabled: minDisabled
    }).on('slideStart', onSlideStart).on('slideStop', onSlideStop);
    return this;
  },
  handleChange: function handleChange() {
    var _this2 = this;
    var value = this.$('input').slider('getValue');
    this.change(function () {
      return _this2.props.set('value', value);
    });
  },
  refresh: function refresh() {
    // Note: refreshing slider sets from original options (which includes original value)
    // explicitly override with current value
    this.$('input').slider('setAttribute', 'value', this.props.get('value'));
    this.$('input').slider('refresh');
  }
});
export default SliderComponent;
SliderComponent.registerAs('slider');