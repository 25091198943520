import { isString } from 'lodash';

/**
  Create changer container
  - Only call change callback if not currently changing

  @return {function(callback, context)}
*/
export function changer() {
  return function () {
    var changing = false;
    return function change(callback, context) {
      if (!changing) {
        changing = true;
        callback.call(context);
        changing = false;
      }
    };
  }();
}
export function toProperCase(str) {
  return str && str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function only(arr, item) {
  return arr.length === 1 && arr[0] === item;
}
export function toBoolean(value) {
  if (isString(value)) {
    return value == 'true';
  } else {
    return Boolean(value);
  }
}