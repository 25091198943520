/* global ga */
import App from './app';
export function track(category, action) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    label = _ref.label,
    value = _ref.value;
  if (category == 'pageview') {
    gtag('event', 'pageview', {
      'page': action,
      'location': location.pathname,
      'title': document.title
    });
    App.log.info("[Track] \"".concat(category, "\", \"").concat(action, "\", location: \"").concat(location.pathname, "\", title: \"").concat(document.title, "\""));
  } else {
    var details = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label || ''
      //eventValue: value, // Note this must be integer (typically for timing events) or the event gets ignored
    };
    gtag('event', 'click', {
      'eventCategory': category,
      'eventAction': action,
      'eventLabel': label || '',
      'eventValue': value || ''
    });
    App.log.info("[Track] \"".concat(category, "\", \"").concat(action, "\", label: \"").concat(label != null ? label : '', "\", value: \"").concat(value != null ? value : '', "\""));
  }
}
export function setFields(fields) {
  gtag('event', 'set', fields);
}