import $ from 'jquery';
import { Promise } from 'rsvp';
import './helpers';
import App from './app';
import AppRouter from './router';
import AppView from './views/app-view';
import TaxesPage from './pages/taxes/index';
import AirlawsPage from './pages/airlaws/index';
import ExpenditurePage from './pages/expenditure/index';
import MinagePage from './pages/minage/index';
import HealthPage from './pages/health/index';
import HomePage from './pages/home-page';
import './components/select-component';
import './components/chart-component';
import './components/slider-component';
import './components/button-component';
import './components/checkbox-component';
import './components/info-component';
import './components/show-component';
import './components/icon-component';
import './components/table-component';
import './components/choropleth-component';
import './components/share-button-component';
import './components/range-dropdown-component';
import './components/explorer-component';
import './components/policy-actions';
import './charts/area';
import './charts/end-year-lines';
import './charts/choropleth';
import './charts/table-labels';
import './charts/table-legend';
import './charts/overlay-text';
import './charts/centered-title';
import './charts/na-overlay';
import './charts/end-value-axis';
import './charts/end-value-labels';
import './charts/end-value-points';
import './charts/end-year-labels';
import './charts/transition-lines';
window.Promise = window.Promise || Promise;
$(document).ready(function () {
  // Router
  // ------
  App.router = new AppRouter();

  // App view
  // --------
  var airlaws = new AirlawsPage({
    el: $('#airlaws')[0]
  });
  var taxes = new TaxesPage({
    el: $('#taxes')[0]
  });
  var expenditure = new ExpenditurePage({
    el: $('#expenditure')[0]
  });
  var minage = new MinagePage({
    el: $('#minimum-age')[0]
  });
  var health = new HealthPage({
    el: $('#health')[0]
  });
  var home = new HomePage({
    el: $('#home')[0]
  });
  var el = $('.shgpol-app')[0];
  App.view = new AppView({
    pages: {
      home: home,
      airlaws: airlaws,
      taxes: taxes,
      expenditure: expenditure,
      health: health,
      'minimum-age': minage
    },
    el: el
  });
  $.fn.popover.Constructor.DEFAULTS.whiteList["div"].push("style");
  $.fn.popover.Constructor.DEFAULTS.whiteList["hr"].push("style");
  $.fn.popover.Constructor.DEFAULTS.whiteList["br"].push("style");

  // Startup the application
  App.startup();
});