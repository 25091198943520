import { chain, assign, includes } from 'lodash';
import * as d3c from 'd3-compose';
import { format, initial_year_fix } from '../config';
import config from '../config';
import { getLegendData } from '../charts/table-legend';
var createScale = d3c.helpers.createScale;
export default function createLineChart(options) {
  var title_text = options.title,
    subtitle_text = options.subtitle,
    _options$is_reduction = options.is_reduction,
    is_reduction = _options$is_reduction === void 0 ? false : _options$is_reduction,
    _options$x_key = options.x_key,
    xKey = _options$x_key === void 0 ? 'year' : _options$x_key,
    _options$y_key = options.y_key,
    yKey = _options$y_key === void 0 ? 'y' : _options$y_key,
    _options$y_tick_forma = options.y_tick_format,
    y_tick_format = _options$y_tick_forma === void 0 ? format.percent0 : _options$y_tick_forma,
    y_axis_text = options.y_axis_title,
    _options$is_all = options.is_all,
    is_all = _options$is_all === void 0 ? false : _options$is_all,
    _options$is_NA = options.is_NA,
    is_NA = _options$is_NA === void 0 ? false : _options$is_NA,
    startYear = options.start_year,
    _options$has_policy = options.has_policy,
    has_policy = _options$has_policy === void 0 ? true : _options$has_policy,
    _options$filtered = options.filtered,
    filtered = _options$filtered === void 0 ? [] : _options$filtered,
    _options$show_start_v = options.show_start_value,
    show_start_value = _options$show_start_v === void 0 ? false : _options$show_start_v;
  var end_value = options.end_value;
  var xScale = createScale(options.xScale);
  var yScale = createScale(options.yScale);
  var x_axis_text = xKey === 'year' ? 'End of year' : 'End of age';
  var end_label_format = function end_label_format(value, d) {
    var prefix = options.is_all ? "".concat(d.cohort, ": ") : "";
    return prefix + (options.small_label_format || y_tick_format)(value);
  };
  return function (data) {
    if (!data || data.error) {
      if (data.error) console.error(data.error);
      return [d3c.title({
        text: 'Error loading data',
        "class": 'no-data',
        margins: {
          top: 160
        }
      })];
    } else if (!data.length) {
      return [d3c.title({
        text: 'No data available at this time',
        "class": 'no-data',
        margins: {
          top: 160
        }
      })];
    }
    var duration = data.__deferred || window.__no_transition ? 0 : 1000;

    // Extract data for chart components
    data = addDifferenceValues(data);
    var line_data = getLineData(data, {
      is_reduction: is_reduction
    });
    var area_data = getAreaData(data, {
      is_reduction: is_reduction
    });
    // Floating end value
    if (options.is_single_cohort) {
      end_value = Math.min(Number(data[0].meta.cohort) + config.cohort_length, end_value);
    }
    var end_value_data = getSmallLabelData(line_data, {
      xScale: xScale,
      end_value: end_value
    });
    var end_label_data = getEndLabelData(line_data);
    if (options.chart_scaling == 'dynamic') {
      var max = data.reduce(function (memo, series) {
        return Math.max(memo, d3.max(series.values, function (d) {
          return d[yKey];
        }));
      }, 0);
      yScale = createScale({
        domain: [0, max]
      });
    }

    // Charts
    var charts = is_NA ? [{
      type: 'NAOverlay'
    }] : [{
      type: 'EndValuePoints',
      id: 'end-value-points',
      data: end_value_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      duration: duration,
      end_value: end_value,
      filtered: filtered
    }, {
      type: 'EndYearLines',
      id: 'line' + (is_all ? '-all' : ''),
      data: line_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      startYear: startYear,
      duration: duration,
      filtered: filtered
    }, has_policy && {
      type: 'Area',
      id: 'area' + (is_all ? '-all' : ''),
      data: area_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      duration: duration,
      filtered: filtered
    }, {
      type: 'EndYearLabels',
      data: end_label_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      position: 'right',
      format: end_label_format,
      delay: duration,
      duration: duration / 4,
      end_value: end_value,
      filtered: filtered
    }, {
      type: 'EndValueLabels',
      id: 'end-value-labels',
      data: end_value_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      format: end_label_format,
      delay: duration,
      duration: duration / 4,
      position: 'right',
      end_value: end_value,
      filtered: filtered
    }].filter(Boolean);
    var title = d3c.title({
      text: title_text,
      "class": 'chart-title-main',
      margins: {
        top: 0,
        bottom: 0,
        left: 0
      }
    });
    var sub_title = d3c.title({
      text: subtitle_text,
      "class": 'chart-subtitle',
      margins: {
        top: 0,
        bottom: 0,
        left: 0
      }
    });

    // Axes
    var x_axis_title = d3c.axisTitle(x_axis_text);
    var y_axis_title = d3c.axisTitle(y_axis_text);
    var x_domain = xScale.domain();
    var x_axis = {
      type: 'EndValueAxis',
      key: 'xAxis',
      scale: xScale,
      position: 'bottom',
      end_value: end_value,
      tickFormat: format.four,
      tickValues: [x_domain[0] + initial_year_fix, x_domain[1]],
      // Ticks start at policy year + 1
      duration: 0
    };
    var y_domain = yScale.domain();
    var first_point = line_data.length && line_data[0].values[0];
    var y_tick_values = !is_all && show_start_value && first_point && first_point[yKey] > 0.03 ? [y_domain[0], first_point[yKey], y_domain[1]] : [y_domain[0], y_domain[1]];
    if (y_tick_values[y_tick_values.length - 1] !== y_domain[1]) y_tick_values.push(y_domain[1]);
    var y_axis = d3c.axis('yAxis', {
      scale: yScale,
      position: 'left',
      tickFormat: y_tick_format,
      tickValues: y_tick_values,
      duration: duration
    });

    // Legend
    var legend_data = getTableLegendData(line_data, {
      has_policy: has_policy,
      is_reduction: is_reduction
    });
    var legend = {
      id: 'legend' + (is_all ? '-all' : ''),
      type: 'TableLegend',
      data: legend_data,
      centered: true,
      swatchDimensions: {
        width: 20,
        height: 15
      },
      margins: {
        bottom: 15
      },
      rowPadding: 0,
      align: 'center',
      columnWidth: 'max',
      filtered: filtered
    };

    // Layout
    return [title, sub_title, legend, [y_axis_title, y_axis, d3c.layered(charts)], x_axis, x_axis_title];
  };
}
export function addDifferenceValues(data) {
  // Add y0 and ydiff values to series and sort into baseline and policy
  // (used in reduction and area)
  var pairs = getUniquePairs(data);
  var unmatched = data.filter(function (series) {
    return !includes(pairs, series.name);
  });
  var baseline_series = [];
  var policy_series = [];
  pairs.forEach(function (pair_key) {
    var pair = data.filter(function (series) {
      return series.name == pair_key;
    });
    var baseline = pair[0];
    var policy = pair[1];
    var baseline_values = baseline.values.map(function (d, i) {
      var y0 = d.y;
      var y1 = policy.values[i].y;
      var diff = d.y - policy.values[i].y;
      var ydiff = diff < 0 ? 0 : diff; // TODO review making prev reduction 0 if negative.

      return assign({
        y0: y0,
        y1: y1,
        ydiff: ydiff
      }, d);
    });
    var policy_values = policy.values.map(function (d, i) {
      return assign({
        y0: baseline_values[i].y0,
        y1: baseline_values[i].y1,
        ydiff: baseline_values[i].ydiff
      }, d);
    });
    baseline_series.push(assign({}, baseline, {
      values: baseline_values
    }));
    policy_series.push(assign({}, policy, {
      values: policy_values
    }));
  });
  return unmatched.concat(baseline_series).concat(policy_series);
}
export function getLineData(data, options) {
  if (!options.is_reduction) {
    return data;
  }

  // Add reduction information
  // (while only "baseline" line is shown, both are needed for standard labels)
  return data.map(function (series) {
    return assign({}, series, {
      key: series.key + '-reduction',
      "class": series['class'] + ' reduction'
    });
  });
}
export function getAreaData(data, options) {
  if (options.is_reduction) {
    return [];
  }

  // Policy series have y0 and ydiff, extract and add area information
  var area_data = getUniquePairs(data).map(function (pair_key) {
    var pair = data.filter(function (series) {
      return series.name == pair_key;
    });
    var policy = pair[1];
    return assign({}, policy, {
      exclude_from_legend: true
    });
  });
  return area_data;
}
function getUniquePairs(data) {
  var unique_pairs = chain(data).map(function (series) {
    return series.name;
  }).uniq().filter(function (pair_key) {
    var pair = data.filter(function (series) {
      return series.name == pair_key;
    });
    return pair.length == 2;
  }).value();
  return unique_pairs;
}
export function getNoPolicyLabels(end_year) {
  if (!end_year) return [];
  var parts = end_year.values[0].key.split('-');
  var labels = [];
  var time_span = end_year.values[0].first_point.year + '-' + end_year.x;
  if (parts[2] === 'deaths_avoided') {
    labels.push([{
      header: true,
      text: 'Deaths avoided from ' + time_span,
      colspan: 2
    }]);
  } else if (parts[2] === 'lys_gained') {
    labels.push([{
      header: true,
      text: 'Life-years gained from ' + time_span,
      colspan: 2
    }]);
  }
  end_year.values.forEach(function (series, i) {
    // TODO: Review
    if (series.end_year_point) {
      labels.push([{
        header: true,
        text: end_year.values[i].name,
        "class": 'table-labels-row-header'
      }, {
        key: i,
        type: 'Lines',
        "class": series['class'],
        text: format.si3(series.end_year_point.y)
      }]);
    }
  });
  return labels;
}
export function getTableLegendData(data, _ref) {
  var has_policy = _ref.has_policy,
    is_reduction = _ref.is_reduction,
    policy_only = _ref.policy_only;
  var rows = [[], []];
  var chart = {
    id: 'line',
    type: 'Lines',
    options: function options() {
      return {};
    }
  };
  var baseline, policy;
  if (is_reduction) {
    rows[0].push(null);
    rows[1].push(''); // was Reduction

    // For reduction, only half the data is needed for legend
    // (other half gets filtered in end-year-lines)
    var count = data.length / 2;
    var series;
    for (var i = 0; i < count; i++) {
      series = data[i];
      rows[0].push(series.name);
      rows[1].push(getLegendData(chart, series, i));
    }
  } else if (policy_only && data.length > 3) {
    // allow for a legend with only policy (dashed) series showing
    var _count = data.length / 2;
    for (var _i = 0; _i < _count; _i++) {
      baseline = data[_i];
      policy = data[_i + _count];
      rows[0].push(baseline.name);
      rows[1].push(getLegendData(chart, policy, _i + _count));
    }
  } else if (has_policy) {
    rows.push([]);
    rows[0].push(null);
    rows[1].push('Baseline');
    rows[2].push('Policy');
    var _count2 = data.length / 2;
    for (var _i2 = 0; _i2 < _count2; _i2++) {
      baseline = data[_i2];
      policy = data[_i2 + _count2];
      rows[0].push(baseline.name);
      rows[1].push(getLegendData(chart, baseline, _i2));
      rows[2].push(getLegendData(chart, policy, _i2 + _count2));
    }
  } else {
    data.forEach(function (series, i) {
      rows[0].push(series.name);
      rows[1].push(getLegendData(chart, series, i));
    });
  }
  return rows;
}
export function getSmallLabelData(line_data, options) {
  var _options$end_value = options.end_value,
    end_value = _options$end_value === void 0 ? 2100 : _options$end_value,
    both_key = options.both_key;
  return line_data.filter(function (series) {
    return !isBaseline(series.key) && (!both_key || series.key === both_key);
  }).map(function (series) {
    var values = series.values.filter(function (point) {
      return end_value < 100 ? point.age === String(end_value) : point.year === end_value;
    });
    return assign({}, series, {
      original_values: series.values,
      values: values
    });
  });
}
export function getEndLabelData(line_data) {
  return line_data.filter(function (series) {
    return !isBaseline(series.key);
  }).map(function (series) {
    var values = [series.values[series.values.length - 1]];
    return assign({}, series, {
      original_values: series.values,
      values: values
    });
  });
}
function isBaseline(key) {
  return key.indexOf('baseline') > -1;
}