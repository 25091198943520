import { Store as DataManagerStore } from 'data-manager';
export var PrevalenceStore = {
  create: function create() {
    var store = new DataManagerStore();
    var _cast = DataManagerStore.generateCast({
      year: 'Number',
      cohort: 'String',
      males_baseline: 'Number',
      females_baseline: 'Number',
      males_policy: 'Number',
      females_policy: 'Number',
      both_baseline: 'Number',
      both_policy: 'Number',
      policy_year: 'Number'
    }, store.types);
    store.cast(function (row, index, details) {
      // Cast values and only return rows with valid year
      row = _cast(row, index, details, store.types);
      if (row.year > 0) return row;else return null;
    });

    // Map rows to x-y values
    store.map({
      y: {
        columns: ['males_baseline', 'females_baseline', 'males_policy', 'females_policy', 'both_baseline', 'both_policy'],
        category: 'result_type'
      }
    });
    return store;
  }
};
export var prevalence_store = PrevalenceStore.create();
export var DeathsAvoidedStore = {
  create: function create() {
    var store = new DataManagerStore();
    var _cast = DataManagerStore.generateCast({
      year: 'Number',
      cohort: 'String',
      deaths_avoided_males: 'Number',
      deaths_avoided_females: 'Number',
      policy_year: 'Number'
    }, store.types);
    store.cast(function (row, index, details) {
      // Cast values and only return rows with valid year
      row = _cast(row, index, details, store.types);

      // TODO: Review. This rounding was added to avoid negative values and the 1//1000 abreviation 'm'
      row.deaths_avoided_males = round_to_zero(row.deaths_avoided_males);
      row.deaths_avoided_females = round_to_zero(row.deaths_avoided_females);
      // Eventually, the both column for deaths avoided and LYGs in the CSV will disappear to reduce filesize
      row.deaths_avoided_both = round_to_zero(row.deaths_avoided_males + row.deaths_avoided_females);
      row.all_cohort = isNaN(row.cohort); // Review, maybe better way to do this
      row.age = String(row.year - row.cohort);
      if (row.year > 0) return row;else return null;
    });

    // Map rows to x-y values
    store.map({
      y: {
        columns: ['deaths_avoided_males', 'deaths_avoided_females', 'deaths_avoided_both', 'cohort'],
        category: 'result_type'
      }
    });
    return store;
  }
};
export var deaths_avoided_store = DeathsAvoidedStore.create();
export var LysGainedStore = {
  create: function create() {
    var store = new DataManagerStore();
    var _cast = DataManagerStore.generateCast({
      year: 'Number',
      cohort: 'String',
      policy_year: 'Number',
      cLYG_males: 'Number',
      cLYG_females: 'Number'
    }, store.types);
    store.cast(function (row, index, details) {
      // Cast values and only return rows with valid year
      row = _cast(row, index, details, store.types);

      //TODO: Review. This rounding was added to avoid negative values and the 1//1000 abreviation 'm'
      row.lys_gained_males = round_to_zero(row.cLYG_males);
      row.lys_gained_females = round_to_zero(row.cLYG_females);
      // Eventually, the both column for deaths avoided and LYGs in the CSV will disappear to reduce filesize
      row.lys_gained_both = round_to_zero(row.cLYG_males + row.cLYG_females);
      row.all_cohort = isNaN(row.cohort); // TODO: Review, maybe better way to do this
      row.age = String(row.year - row.cohort);
      if (row.year > 0) return row;else return null;
    });

    // Map rows to x-y values
    store.map({
      y: {
        columns: ['lys_gained_males', 'lys_gained_females', 'lys_gained_both', 'cohort'],
        category: 'result_type'
      }
    });
    return store;
  }
};
export var lys_gained_store = LysGainedStore.create();
function round_to_zero(d) {
  // temporarily preventing rounding
  // if (d < 0.5) return 0; // for negative and close to 0
  // if (d < 1) return 1;
  // else return d;
  return d;
}