import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = function format_percent(d) {
  return '$' + d3_format(',.2f')(d);
};
var default_NY_workplaces = format_percent(default_params.NY.original.ppp);
var default_MO_workplaces = format_percent(default_params.MO.original.ppp);
var HealthTourView = TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Graphic Health Warnings Policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      backdrop: true,
      element: '#slideshow-ghw img',
      placement: 'top',
      title: 'Graphic Health Warning',
      content: "<p>Below is a slideshow of federally-approved images to be used as graphic health warnings. Please feel free to stop or start this slideshow by clicking on the displaying image.</p>\n        "
    }, {
      element: '[data-id="health"] .initiation-cessation-controls',
      placement: 'bottom',
      backdrop: true,
      title: 'Evaluate policy effects scenarios',
      content: "<p>Evaluate different policy effects scenarios by choosing rates of reduced smoking initiation and increased cessation from the dropdown menus.</p>"
    }, {
      element: '#initiation',
      title: 'Choose the reduction of smoking initiation',
      placement: 'bottom',
      backdrop: true,
      content: "<p>First, choose the % reduction in smoking initiation resulting from this policy.</p>"
    }, {
      element: '#cessation',
      title: 'Choose the increase in smoking cessation',
      placement: 'bottom',
      backdrop: true,
      content: "<p>Next, choose the % increase in smoking cessation resulting from this policy.</p>"
    }, {
      element: '#health-end-value',
      title: 'Choose horizon',
      backdrop: true,
      placement: 'bottom',
      content: "<p>Finally, choose the horizon of your new policy. The horizon is simply the end year you are interested in seeing results for. If you don't know just leave the start date and horizon as is.</p>"
    }, {
      element: '[data-id="health"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      backdrop: true,
      content: "\n        <p>A convenient summary of your policy is available below the policy parameters.</p>"
    }, {
      element: '[data-id="health"] #explorer-overview',
      title: 'Results',
      placement: 'top',
      backdrop: true,
      content: "<p>The values shown in the charts below are derived from simulations, using the Smoking History Generator population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention and Surveillance Modeling Network (CISNET)</a>.\n          This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p>\n          <p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the Graphic Health Warnings policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});
export default HealthTourView;