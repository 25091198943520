import { assign } from 'lodash';
import { format as d3_format } from 'd3';
import config, { format, domains } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import App from '../../app';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
var progress_bar = App.template('progress-bar');
export var choropleth = {
  policy: 'airlaws',
  title: 'Smoke-free air law coverage by state (2018)',
  source: '',
  domain: [0.5, 1],
  // VA has worst current laws
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    var progress = function progress(label, value, asterisk) {
      var override = {
        w: value * 9 / 6,
        r: 0,
        b: 0
      };
      var percent = Math.round(value * 100);
      var offset = percent < 40;
      var fill = stateFill(assign({}, state, {
        override: override
      }));
      var color = offset ? '#000' : labelColor(assign({}, state, {
        override: override
      }));
      return progress_bar({
        percent: percent,
        percent_text: "".concat(label, " ").concat(format.percent0(value)).concat(asterisk ? '*' : ''),
        fill: fill,
        color: color,
        offset: offset
      });
    };
    return "Smoke-free air law coverage (2017):\n      ".concat(progress('Workplaces', d.w, true), "\n      ").concat(progress('Restaurants', d.r), "\n      ").concat(progress('Bars', d.b), "\n      <br style=\"display: block; content: ' '; height: 4px;\">\n      ").concat(progress('Weighted average', weightedAverage(d)), "\n      <div class='footer'>\n        *").concat(format.percent0(d.w), " reported in survey, ").concat(format.percent0(d.w_old), " by law\n      </div>\n      <div class=\"hint\">\n        <strong>Hint:</strong> Click the state to set the initial coverages (rounded to nearest 25%) and propose an increase to 100% for each venue.\n      </div>");
  },
  fillValue: weightedAverage,
  legend_format: d3_format('.1p'),
  can_click_states: true,
  include_overlay_state_title: true
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].airlaws.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].airlaws.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].airlaws.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].airlaws.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].airlaws.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].airlaws.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].airlaws.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].airlaws.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
function weightedAverage(d) {
  return d.w * 6 / 9 + d.r * 2 / 9 + d.b * 1 / 9;
}
function getSubtitle(choices) {
  var coverage = ['w', 'r', 'b'].map(function (place) {
    var end_coverage = choices['ban_' + place] && choices['per_' + place] != 100 ? '→100%' : '';
    return config.places[place] + ': ' + choices['per_' + place] + '%' + end_coverage;
  });
  return "Policy coverage (".concat(coverage.join(', '), ")");
}