import { View } from 'backbone';
import App from '../app';
import { pick, includes } from 'lodash';
import { stratification_types } from '../config';
var title_base = document.title;
var shared_model_keys = ['us_state', 'policy_year', 'age_group', 'chart_scaling', 'exploring', 'filtered'];
var shared_state_keys = [];
var valid_minage_stratifications = ['combined', '2000', '2010', '2020', 'all', 'all-by-age'];
var valid_other_stratifications = stratification_types.map(function (type) {
  return type.value;
});

/**
  AppView

  Handles top navigation and initializing/showing subviews

  @param {Object} pages Mapping of page id to view instance
*/
var AppView = View.extend({
  initialize: function initialize(options) {
    this.pages = options.pages || {};
    this.scroll = {};
    this.nav = this.$('nav li');
    this.panels = this.$('[role="tabpanel"]');

    // Convert id to data-id to avoid inadvertent scrolling
    this.panels.each(function () {
      this.dataset.id = this.id;
      this.removeAttribute('id');
    });
  },
  setActive: function setActive(id) {
    var previous = this.active;
    if (id === previous) {
      this.pages[id].trigger('update');
      return;
    }

    // Check for outlet
    var panel = this.$("[data-id=\"".concat(id, "\"]"));
    if (!panel.length) {
      App.log.error("No page found for \"".concat(id, "\""));
      return;
    }

    // Deactivate current view
    var shared_model,
      shared_state,
      notifications = [];
    if (this.pages[previous]) {
      shared_model = pick(this.pages[previous].model.attributes, shared_model_keys);
      shared_state = pick(this.pages[previous].state.attributes, shared_state_keys);
      var previous_end_value = this.pages[previous].state.get('end_value');
      if (id !== 'minimum-age' && previous_end_value <= 2100) {
        shared_state.end_value = previous_end_value;
      } else {
        var changed = this.pages[id] && previous_end_value !== this.pages[id].state.get('end_value');
        if (changed) notifications.push('end_value');
      }
      var previous_stratification = this.pages[previous].model.get('stratification');
      var valid_stratifications = id === 'minimum-age' ? valid_minage_stratifications : valid_other_stratifications;
      if (includes(valid_stratifications, previous_stratification)) {
        shared_model.stratification = previous_stratification;
      } else {
        notifications.push('stratification');
      }
      this.pages[previous].trigger('deactivate');
      this.scroll[previous] = window.scrollY;
    }

    // Activate panel
    this.active = id;
    this.panels.removeClass('active');
    panel.addClass('active');

    // Activate navigation
    this.nav.removeClass('active');
    this.nav.find("[href=\"#".concat(id, "\"]")).parent().addClass('active');

    // Set title from data-title
    this.setTitle(panel.data('title'));

    // Activate view
    if (this.pages[id]) {
      if (id != 'health') {
        if (shared_model) this.pages[id].model.set(shared_model, {
          override: true
        });
        if (shared_state) this.pages[id].state.set(shared_state, {
          override: true
        });
      }
      this.pages[id].trigger('activate');
      if (notifications.length) {
        this.pages[id].trigger('notify', notifications);
      }
    }
    this.$('.navbar-collapse').collapse('hide');

    // Restore scroll position
    var scroll_position = this.scroll[id] || 0;
    window.scroll(0, scroll_position);
  },
  setTitle: function setTitle(title) {
    document.title = (title ? title + ' - ' : '') + title_base;
  }
});
export default AppView;