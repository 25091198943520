import { assign, bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { default_data_folder, default_policy_year, default_horizon } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withStateDefaults, withEditInitial, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import AirlawsTourView from '../../views/airlaws-tour-view';
import { toBoolean } from '../../utils';
var getStateDefaults = function getStateDefaults(us_state) {
  var defaults = config.default_params[us_state];
  return {
    per_w_law: defaults.w_law,
    per_w_reported: defaults.w_reported,
    per_w: defaults.w,
    per_r: defaults.r,
    per_b: defaults.b,
    ban_w: true,
    ban_r: true,
    ban_b: true
  };
};
export default PageView.extend({
  title: 'Smoke-free Air Laws',
  template: App.template('airlaws'),
  defaults: function defaults() {
    return new Model({
      policy: 'airlaws',
      policy_year: default_policy_year,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_horizon,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: []
    });
  },
  conversions: {
    policy_year: Number,
    per_w: Number,
    per_r: Number,
    per_b: Number,
    ban_w: toBoolean,
    ban_r: toBoolean,
    ban_b: toBoolean
  },
  excludeFromQuerystring: ['per_w_law', 'per_w_reported', 'by_cohort', 'age_period'],
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    withStateDefaults(this, getStateDefaults);
    withEditInitial(this, getStateDefaults);
    withEndValue(this);
    withFiltered(this);
    withNotify(this, 'airlaws');
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: computed(this.model, ['ban_w', 'ban_r', 'ban_b', 'per_w', 'per_r', 'per_b'], function (ban_w, ban_r, ban_b, per_w, per_r, per_b) {
        var us_state = _this.model.get('us_state');
        var us_state_label = _this.config.us_states[us_state].label;
        var defaults = getStateDefaults(us_state);
        var all_defaults_selected = defaults.per_w == _this.model.get('per_w') && defaults.per_w == _this.model.get('per_w') && defaults.per_r == _this.model.get('per_r') && defaults.per_b == _this.model.get('per_b');
        var standard = function standard(reason) {
          return "<p>The parameters you have chosen result in little or no effect on mortality or prevalence.</p>\n              <p><strong>Reason:</strong> ".concat(reason, "</p>");
        };
        if (defaults.per_w == 100 && defaults.per_r == 100 && defaults.per_b == 100 && all_defaults_selected) {
          return "<p>".concat(us_state_label, "  already has a strong airlaws policy in place that reduces smoking prevalence and smoking-related deaths. As such, improving airlaw coverage further would have little or no effect.</p>\n            <p>To explore further, click the <span class=\"icon-component icon-lock\"><svg><use xlink:href=\"#icon-lock\"></use></svg></span>icon to the left to unlock and change the default values.</p>");
        } else if (ban_w == false && ban_r == false && ban_b == false) {
          return standard("It appears that no change to the initial smoke-free air laws has been proposed (all venues remain at their initial coverage).");
        } else if (per_w == 100 && per_r == 100 && per_b == 100) {
          return standard("It appears that the proposed policy assumes that there is already 100% coverage for all venues so applying a new law would have little or no effect. At least one venue must have a current coverage of less than 100% for there to be an effect.");
        } else if ((per_w == 100 || ban_w == false) && (per_r == 100 || ban_r == false) && (per_b == 100 || ban_b == false)) {
          return standard("It appears that no relevant new smoke-free air laws have been applied.");
        }
      })
    });
    this.choropleth = charts.choropleth;
    this.tour = new AirlawsTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
    var getBanOptions = function getBanOptions(value) {
      return [{
        value: true,
        label: 'Increase to 100%'
      }, {
        value: false,
        label: "No change (stay at ".concat(value, "%)")
      }];
    };
    this.ban_w_options = computed(this.model, 'per_w', getBanOptions);
    this.ban_r_options = computed(this.model, 'per_r', getBanOptions);
    this.ban_b_options = computed(this.model, 'per_b', getBanOptions);
  },
  config: config,
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default air laws' : 'Click to change air laws (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});