import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = d3_format('.2p');
var default_NV_mla_pac21 = format_percent(default_params.NV.original.mla_pac21);
var default_CA_mla_pac21 = format_percent(default_params.CA.original.mla_pac21);
export default TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Minimum Age of Legal Access policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      backdrop: true,
      element: '[data-id="minimum-age"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>Prior to the 2020 federal law raising the Minimum Age of Legal Access (MLA) to age 21, each state had existing MLA policies.</p><p>The colors in this map represent the Percentage of population Already Covered by Tobacco 21 (PAC 21) for each state as of 2019. States that had low coverage are colored darker while states that had high coverage are colored lighterEach state in the U.S. has an existing Minimum Age of Legal Access (MLA) policy.</p> <p>Hover your mouse pointer over the map to see the specific current coverage for each state.</p>"
    }, {
      backdrop: true,
      element: '[data-id="minimum-age"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>For example, in the state of California the Tobacco 21 coverage was ".concat(default_CA_mla_pac21, " in 2019. States like California have the <strong>least to gain</strong> from a policy that increases the minimum age of legal access to tobacco.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'CA'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="minimum-age"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>In contrast, in the state of Nevada the Tobacco 21 coverage was ".concat(default_NV_mla_pac21, " in 2019. States like Nevada with a low current coverage have the <strong>most to gain</strong> from a policy that increases the minimum age of legal access.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'NV'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="minimum-age"] .policy-choropleth-container',
      placement: 'top',
      title: 'How to use the tool',
      content: "<p>To determine the effects of raising the minimum age of legal access you start by clicking your mouse on the region (either a specific state or an average of all states) in the map. This will set the model parameters to match the current Tobacco 19 and 21 coverage that region.</p>"
    }, {
      element: '[data-id="minimum-age"] #minage-policy-year',
      placement: 'bottom',
      backdrop: true,
      title: 'Choose the policy start year',
      content: "<p>Then choose the start year of your new policy (default=".concat(default_policy_year, ").</p>")
    }, {
      element: '[data-id="minimum-age"] #minage-mla',
      title: 'Choose proposed MLA',
      placement: 'bottom',
      backdrop: true,
      content: "<p>Select the proposed minimum age policy you wish to simulate (19, 21, or 25).</p>"
    }, {
      element: '[data-id="minimum-age"] .minage-edit-initial-region',
      title: 'Change initial MLA',
      placement: 'bottom',
      backdrop: true,
      content: "<p>The initial Tobacco 19 and 21 coverage is automatically set based on the current region/state. However you can override the initial coverage by clicking on the <svg class=\"icon-component\" style=\"margin-right: -10px;\"><use xlink:href=\"#icon-lock\" style=\"fill: #BBB;\"></use></svg> symbol</p>"
    }, {
      element: '[data-id="minimum-age"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      backdrop: true,
      content: "<p>A convenient summary of your policy is available below the policy parameters.</p>"
    }, {
      element: '[data-id="minimum-age"] #explorer-overview',
      title: 'Results',
      backdrop: true,
      placement: 'top',
      content: "<p>The values used in the charts are derived from simulations, using the Smoking History Generator population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention Surveillance Network (CISNET)</a>. This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p><p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the Minimum Age policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});