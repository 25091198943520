import { Axis, helpers } from 'd3-compose';
import { assign } from 'lodash';
var property = helpers.property;
Axis.extend('EndValueAxis', {
  initialize: function initialize(options) {
    var _this = this;
    Axis.prototype.initialize.call(this, options);
    var onEndValue = function onEndValue(end_value) {
      _this.end_value(end_value);
      _this.draw();
    };
    var onTransientValue = function onTransientValue(end_value) {
      _this.transient_end_value(end_value);
      _this.draw();
    };
    this.on('attach', function () {
      _this.container.on('change:end_value', onEndValue);
      _this.container.on('change:transient_end_value', onTransientValue);
    });
    this.on('detach', function () {
      _this.container.off('change:end_value', onEndValue);
      _this.container.off('change:transient_end_value', onTransientValue);
    });
  },
  tickValues: property({
    set: function set(value) {
      this.__original_tick_values = value;
    }
  }),
  draw: function draw() {
    var end_value = this.transient_end_value() || this.end_value();
    var tickValues = [end_value].concat(this.__original_tick_values.filter(function (value) {
      return Math.abs(value - end_value) >= 5;
    })).sort(byAscending);
    this.options().tickValues = tickValues;
    Axis.prototype.draw.call(this);
  },
  end_value: property(),
  transient_end_value: property()
});
function byAscending(a, b) {
  return a - b;
}