import { bindAll } from 'lodash';
import { Component } from 'backbone-component';
import { track } from '../analytics';
import App from '../app';
var ShareButtonComponent = Component.extend({
  tagName: 'span',
  className: 'share-button-component',
  template: App.template('share-button-component'),
  events: {
    'hide.bs.popover .button-component': 'onHidePopover'
  },
  initialize: function initialize() {
    bindAll(this, 'onShare', 'onCopyToClipboard', 'hidePopover', 'onHidePopover');
    $('body').on('click', '.copy-to-clipboard', this.onCopyToClipboard);
  },
  onShare: function onShare() {
    var _this = this;
    if (this.popover) {
      return;
    }
    var url = window.location.href;
    this.popover = this.$('.button-component').popover({
      content: "\n          <div class=\"input-group\">\n            <input class=\"share-field form-control\" type=\"text\" value=\"".concat(url, "\" onClick=\"this.setSelectionRange(0, this.value.length)\">\n            <span class=\"input-group-btn\">\n              <button class=\"btn btn-default copy-to-clipboard\" type=\"button\" aria-label=\"Copy to clipboard\" title=\"Copy to clipboard\">\n                <div class=\"icon-component\"><svg>\n                  <use xlink:href=\"#icon-copy\"></use>\n                </svg></div>\n              </button>\n            </span>\n          </div>\n        "),
      html: true,
      placement: 'bottom',
      container: 'body',
      trigger: 'manual',
      template: "\n          <div class=\"popover share-popover\" role=\"tooltip\">\n            <div class=\"arrow\"></div>\n            <h3 class=\"popover-title\"></h3>\n            <div class=\"popover-content\"></div>\n          </div>\n        ",
      whiteList: {
        div: ["class"],
        span: ["class"],
        button: ["class", "type", "aria-label", "title"],
        input: ["class", "type", "value", "onClick"],
        svg: [],
        use: ["xlink:href"]
      }
    }).popover('show');
    $('.copy-to-clipboard').tooltip({
      placement: 'bottom',
      delay: {
        show: 1000,
        hide: 0
      }
    });
    setTimeout(function () {
      $('body').on('click', _this.hidePopover);
    }, 0);
    track('share', 'show');
  },
  hidePopover: function hidePopover(e) {
    if (!this.popover) {
      return;
    }
    if (e) {
      var popover = $('.share-popover');
      var inside_popover = $(e.target).closest(popover).length > 0;

      // Ignore click on popover
      if (inside_popover) {
        return;
      }
    }
    this.popover.popover('destroy');
  },
  onHidePopover: function onHidePopover() {
    this.popover = null;
    $('body').off('click', this.hidePopover);
  },
  onCopyToClipboard: function onCopyToClipboard(e) {
    var _this2 = this;
    if (this.popover) {
      var button = $(e.currentTarget);
      var field = $('.share-field')[0];
      field.focus();
      field.select();
      var copied = document.execCommand('copy');
      if (copied) {
        button.attr('title', 'Copied').tooltip('fixTitle').tooltip('show');

        // On hover, title will be changed back
        button.attr('title', 'Copy to clipboard').tooltip('fixTitle');
        setTimeout(function () {
          _this2.hidePopover();
        }, 3000);
        track('copy-to-clipboard', 'copied', {
          label: field.value
        });
      }
    }
  },
  remove: function remove() {
    $('body').off('click', '.copy-to-clipboard', this.onCopyToClipboard);
    $('body').off('click', this.hidePopover);
  }
});
export default ShareButtonComponent;
ShareButtonComponent.registerAs('share-button');