import { bindAll, defer } from 'lodash';
import $ from 'jquery';
import { View } from 'backbone-component';
import { changer } from '../utils';
import Tour from 'bootstrap-tourist';
var TourView = View.extend({
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'stop');
    this.change = changer();
    this.listenTo(this.model, 'change:showing', function () {
      _this.change(function () {
        if (_this.model.get('showing')) {
          _this.start();
        } else {
          _this.stop();
        }
      });
    });
    $('body').on('click', '.tour-tour [data-role="end"]', this.stop);
  },
  render: function render() {
    var _this2 = this;
    var steps = this.steps(this.model);
    this.tour = new Tour({
      steps: steps,
      getProgressBarHTML: function getProgressBarHTML(percent) {
        return "<div class=\"pbar_wrapper\"><hr class=\"pbar\" style=\"width: ".concat(percent, "%;\"></span></div>");
      },
      onEnd: function onEnd() {
        _this2.change(function () {
          _this2.model.set('showing', false);
        });
      },
      template: "<div class='popover tour'>\n        <div class='arrow'></div>\n        <h3 class='popover-title'></h3>\n        <div class='popover-content'></div>\n        <div class='popover-navigation'>\n          <div class=\"btn-group\" role=\"group\">\n            <button class='btn btn-default' data-role='prev'>Prev</button>\n            <button class='btn btn-default' data-role='next'>Next</button>\n          </div>\n          <button class='btn btn-default' data-role='end'>End tour</button>\n        </div>\n      </div>"
    });
    if (this.model.get('showing')) {
      this.start();
    }
    return this;
  },
  start: function start() {
    var _this3 = this;
    this.tour.restart();
    this.change(function () {
      _this3.model.set('showing', true);
    });
  },
  stop: function stop() {
    defer(function () {
      var tour = $('.tour-tour.in');
      if (tour.length) {
        tour.popover('destroy');
      }
    });
  },
  remove: function remove() {
    $('body').off('click', '.tour-tour [data-role="end"]', this.stop);
  }
});
export default TourView;