import { event as d3_event } from 'd3';
import { Chart, helpers } from 'd3-compose';
var translate = helpers.translate;
Chart.extend('NAOverlay', {
  initialize: function initialize(options) {
    var _this = this;
    Chart.prototype.initialize.call(this, options);
    var container;
    this.on('attach', function () {
      container = _this.container;
    });
    var base = this.base.append('g').attr('class', 'na-overlay').append('a').attr('xlink:href', '#reset-na-parameters').on('click', function () {
      d3_event.preventDefault();
      container.trigger('reset-na-parameters');
    }).append('text').attr('text-anchor', 'middle');
    this.layer('text', base, {
      dataBind: function dataBind() {
        return this.selectAll('tspan').data(['No data for this age group', 'Reset age group to 18-99']);
      },
      insert: function insert() {
        return this.append('tspan');
      },
      events: {
        merge: function merge() {
          this.text(function (d) {
            return d;
          }).attr('x', 0).attr('y', function (d, i) {
            return i * 20;
          }).classed('reset-na-parameters', function (d, i) {
            return i === 1;
          });
        }
      }
    });
    this.on('draw', function () {
      _this.base.select('g').attr('transform', translate(_this.width() / 2, _this.height() / 2));
    });
  }
});