import * as d3c from 'd3-compose';
import { format, initial_year_fix } from '../config';
import config from '../config';
import { addDifferenceValues, getLineData, getSmallLabelData as getLabelData } from './line-chart';
import { getTableLegendData } from '../charts/line-chart';
import App from '../app';
var createScale = d3c.helpers.createScale;
export default function createSmallChart(options) {
  var title_text = options.title,
    _options$x_key = options.x_key,
    xKey = _options$x_key === void 0 ? 'year' : _options$x_key,
    _options$y_key = options.y_key,
    yKey = _options$y_key === void 0 ? 'y' : _options$y_key,
    _options$y_tick_forma = options.y_tick_format,
    y_tick_format = _options$y_tick_forma === void 0 ? format.percent0 : _options$y_tick_forma,
    _options$is_reduction = options.is_reduction,
    is_reduction = _options$is_reduction === void 0 ? false : _options$is_reduction,
    _options$is_NA = options.is_NA,
    is_NA = _options$is_NA === void 0 ? false : _options$is_NA,
    _options$is_all = options.is_all,
    is_all = _options$is_all === void 0 ? false : _options$is_all,
    _options$show_start_v = options.show_start_value,
    show_start_value = _options$show_start_v === void 0 ? false : _options$show_start_v,
    start_year = options.start_year,
    both_key = options.both_key;
  var end_value = options.end_value || 2100;
  var xScale = createScale(options.xScale);
  var yScale = createScale(options.yScale);
  var small_label_format = function small_label_format(value, d) {
    var prefix = options.is_all ? "".concat(d.cohort, " ") : '';
    return prefix + (options.small_label_format || y_tick_format)(value);
  };
  return function (data) {
    if (!data || data.error) {
      if (data.error) {
        App.log.error('Error loading small chart data', data.error);
      }
      return [d3c.title({
        text: 'Error loading data',
        "class": 'chart-title-main no-data',
        margins: {
          top: 0,
          bottom: 10,
          left: 0
        }
      })];
    } else if (!data.length) {
      return [d3c.title({
        text: 'No data',
        "class": 'chart-title-main no-data',
        margins: {
          top: 0,
          bottom: 10,
          left: 0
        }
      })];
    }
    var duration = data.__deferred ? 0 : 1000;

    // Extract data for chart components
    data = addDifferenceValues(data);

    // hide dotted baseline in small charts
    var line_data = getLineData(data, {
      is_reduction: is_reduction
    }).filter(function (series) {
      return series.key.indexOf('baseline') == -1;
    });
    if (options.chart_scaling == 'dynamic') {
      var max = data.reduce(function (accumulator, series) {
        return Math.max(accumulator, d3.max(series.values, function (d) {
          return d[options.y_key || 'y'];
        }));
      }, 0);
      yScale = createScale({
        domain: [0, max]
      });
    }

    // Floating end value
    if (options.is_single_cohort) {
      end_value = Math.min(Number(data[0].meta.cohort) + config.cohort_length, end_value);
    }
    var label_data = is_all ? [] : getLabelData(line_data, {
      xScale: xScale,
      both_key: both_key,
      end_value: end_value
    });

    // Legend
    var legend_data = getTableLegendData(line_data, false, false, true);
    var legend = {
      id: 'legend',
      type: 'TableLegend',
      data: legend_data,
      centered: true,
      swatchDimensions: {
        width: 20,
        height: 15
      },
      margins: {
        top: 0,
        left: -40,
        bottom: 10
      },
      rowPadding: 0,
      align: 'center',
      columnWidth: 'max',
      enabled: false
    };

    // Charts
    var charts = is_NA ? [{
      type: 'NAOverlay'
    }] : [{
      // FIXME: EndYearLines fixes TransitionLine line cutoff
      //  but may break functionality
      type: 'EndYearLines',
      id: 'line',
      data: line_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      startYear: start_year,
      duration: duration
    }, {
      type: 'EndValuePoints',
      data: label_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      duration: duration,
      end_value: end_value
    }, {
      type: 'EndValueLabels',
      data: label_data,
      xScale: xScale,
      yScale: yScale,
      xKey: xKey,
      yKey: yKey,
      format: small_label_format,
      delay: duration,
      duration: duration / 4,
      end_value: end_value
    }];

    // Title
    var title = {
      type: 'CenteredTitle',
      text: title_text,
      "class": 'chart-title-main',
      margins: {
        top: 0,
        bottom: 0,
        left: 0
      }
    };

    // Axes
    var x_domain = xScale.domain();
    var x_axis = {
      type: 'EndValueAxis',
      key: 'xAxis',
      scale: xScale,
      position: 'bottom',
      end_value: end_value,
      tickFormat: format.four,
      tickValues: [x_domain[0] + initial_year_fix, x_domain[1]],
      // Ticks start at policy year + 1
      duration: 0
    };
    var y_domain = yScale.domain();
    var first_point = line_data.length && line_data[0].values[0];
    var y_tick_values = !is_all && show_start_value && first_point && first_point[yKey] > 0.03 ? [y_domain[0], first_point[yKey], y_domain[1]] : y_domain;
    var y_axis = d3c.axis('yAxis', {
      scale: yScale,
      position: 'left',
      tickFormat: y_tick_format,
      tickValues: y_tick_values,
      duration: duration
    });

    // Layout
    return [title, legend, [y_axis, d3c.layered(charts)], x_axis];
  };
}