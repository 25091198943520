import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = d3_format('.2p');
var default_CA_expenditures = format_percent(default_params.CA.original.expenditure_percent_cdc);
var default_GA_expenditures = format_percent(default_params.GA.original.expenditure_percent_cdc);
export default TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Tobacco Control Expenditures policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      element: '[data-id="expenditure"] .policy-choropleth-container',
      backdrop: true,
      placement: 'top',
      title: 'Existing policy',
      content: "<p>Each state in the U.S. has an existing Tobacco Control Expenditures policy. The colors in this map represent the current TC expenditure policies for each state. States that have low TC expenditures are colored darker while states that have a high current expenditures value are colored lighter.</p> <p>Hover your mouse pointer over the map to see the specific coverage for each state.</p>"
    }, {
      backdrop: true,
      element: '[data-id="expenditure"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>For example, the state of California has high TC Expenditures at ".concat(default_CA_expenditures, " of the level recommended by the CDC . States like California with high TC expenditures have the <strong>least to gain</strong> from a Tobacco Control Expenditures policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'ND'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="expenditure"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy',
      content: "<p>In contrast, the state of Georgia has a ".concat(default_GA_expenditures, " TC Expenditures value. States like Georgia with a low TC Expenditures value have the <strong>most to gain</strong> from a Tobacco Control Expenditures policy.</p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'GA'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      element: '[data-id="expenditure"] .policy-choropleth-container',
      placement: 'top',
      backdrop: true,
      title: 'How to use the tool',
      content: "<p>To determine the effects of a Tobacco Control Expenditures policy you start by clicking your mouse on the region (either a specific state or an average of all states) in the map. This will set the model parameters to match the current Tobacco Control Expenditures of that region.</p>"
    }, {
      element: '[data-id="expenditure"] #expenditure-policy-year',
      placement: 'bottom',
      backdrop: true,
      title: 'Choose the policy start year',
      content: "<p>Then choose the start year of your new policy (default=".concat(default_policy_year, ").</p>")
    }, {
      element: '[data-id="expenditure"] #expenditure-policy',
      title: 'Choose proposed expenditures',
      placement: 'bottom',
      backdrop: true,
      content: "<p>Choose the proposed level of tobacco control expenditures you wish to simulate. Note that only options larger than the initial expenditures will appear.</p>"
    }, {
      element: '[data-id="expenditure"] .expenditure-edit-initial-region',
      title: 'Change initial level of expenditures',
      placement: 'bottom',
      backdrop: true,
      content: "<p>The initial level of expenditures is automatically set according to the current regsion/state, however you can override it by clicking the <svg class=\"icon-component\"><use xlink:href=\"#icon-lock\" style=\"fill: #BBB\"></use></svg> symbol.</p>"
    }, {
      element: '[data-id="expenditure"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      backdrop: true,
      content: "<p>A convenient summary of your policy is available below the policy parameters.</p>"
    }, {
      element: '[data-id="expenditure"] #explorer-overview',
      title: 'Results',
      backdrop: true,
      placement: 'top',
      content: "<p>The values used in the charts are derived from simulations, using the Smoking History Generator population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention Surveillance Network (CISNET)</a>. This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p><p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the Tobacco Control Expenditures policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});