import { bindAll, extend, indexOf, toArray, isArray, isFunction } from 'lodash';
var levels = ['INFO', 'DEBUG', 'WARNING', 'ERROR'];
export default function Logger() {
  bindAll(this, 'log', 'info', 'debug', 'warning', 'error');
  this.enabled = false;
  this.level = 'DEBUG';
  this.levels = levels;
}
extend(Logger.prototype, {
  log: function log(level, messages) {
    if (!this.enabled) return;
    if (indexOf(levels, level) < indexOf(levels, this.level)) return;
    if (typeof console === 'undefined') return;
    if (arguments.length > 2) messages = toArray(arguments).slice(1);else if (!isArray(messages)) messages = [messages];else messages = toArray(messages);
    if (level == 'ERROR' && isFunction(console.error)) {
      // eslint-disable-line no-console
      console.error.apply(console, messages); // eslint-disable-line no-console
    } else {
      messages.unshift(level);
      console.log.apply(console, messages); // eslint-disable-line no-console
    }
  },
  info: function info() {
    this.log('INFO', toArray(arguments));
  },
  debug: function debug() {
    this.log('DEBUG', toArray(arguments));
  },
  warning: function warning() {
    this.log('WARNING', toArray(arguments));
  },
  error: function error() {
    this.log('ERROR', toArray(arguments));
  }
});