import $ from 'jquery';
import { bindAll, each, assign, defer } from 'lodash';
import { Model } from 'backbone';
import { Component, computed, bound } from 'backbone-component';
import DetailsView from '../views/details-view';
import { track } from '../analytics';
import App from '../app';
import { bindPrevalence, bindPrevalenceReduction } from '../pages/policy-helpers';
var template = App.template('explorer-component');
var print_template = App.template('explorer-component-print');
var ExplorerComponent = Component.extend({
  className: 'explorer-component',
  defaultProps: {
    exploring: 'overview',
    print: false
  },
  template: App.template('explorer-component'),
  events: {
    'click .explorer-nav-link': 'onNavClick',
    'click .explorer-overview-section': 'onOverviewClick'
  },
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onResetNAParameters', 'attachChart');
    var _this$props$attribute = this.props.attributes,
      prevalence = _this$props$attribute.prevalence,
      prevalence_reduction = _this$props$attribute.prevalence_reduction,
      deaths_avoided = _this$props$attribute.deaths_avoided,
      lys_gained = _this$props$attribute.lys_gained,
      warnings = _this$props$attribute.warnings;
    this.prevalence = prevalence;
    this.prevalence_reduction = prevalence_reduction;
    this.deaths_avoided = deaths_avoided;
    this.lys_gained = lys_gained;
    this.warnings = warnings;
    this.charts = [];
    this.rendered = {};
    this.print_views = null;
    this.printing = false;
    this.listenTo(this.props, 'change:exploring', function () {
      _this.showTab(_this.props.get('exploring'));
    });
    this.isOverview = computed(this.props, 'exploring', function (exploring) {
      return exploring === 'overview';
    });
    var end_value = bound(this.props, 'end_value');
    var transient_end_value = bound(this.props, 'transient_end_value');
    var filtered = bound(this.props, 'filtered');
    var createDetails = function createDetails(details) {
      return new DetailsView({
        model: _this.model,
        end_value: end_value,
        transient_end_value: transient_end_value,
        filtered: filtered,
        data: details.explore_data,
        lines: details.explore_lines,
        is_NA: details.explore_is_NA,
        onResetParameters: _this.props.get('onResetParameters')
      });
    };
    this.views = {
      prevalence: createDetails(prevalence),
      prevalence_reduction: createDetails(prevalence_reduction),
      deaths_avoided: createDetails(deaths_avoided),
      lys_gained: createDetails(lys_gained)
    };
    this.active_view = null;
    this.listenTo(end_value, 'change', function (end_value) {
      var updateCharts = function updateCharts() {
        _this.charts.forEach(function (chart) {
          return chart.trigger('change:end_value', end_value);
        });
      };
      if (_this.props.get('exploring') === 'overview') {
        updateCharts();
        return;
      }
      var onChange = function onChange() {
        updateCharts();
        _this.stopListening(_this.props, 'change:exploring', onChange);
      };
      _this.listenTo(_this.props, 'change:exploring', onChange);
    });
    this.value_classes = {
      prevalence: computed(this.prevalence.explore_value, toValueClass),
      prevalence_reduction: computed(this.prevalence_reduction.explore_value, toValueClass),
      deaths_avoided: computed(this.deaths_avoided.explore_value, toValueClass),
      lys_gained: computed(this.lys_gained.explore_value, toValueClass)
    };
    var createPrintDetails = function createPrintDetails(getDetails, _ref, values) {
      var charts = _ref.charts,
        options = _ref.options;
      var model = new Model(assign({}, _this.model.attributes, values));
      var state = new Model({
        end_value: end_value.get(),
        transient_end_value: transient_end_value.get()
      });
      var details = getDetails(model, state, charts, options);
      return new DetailsView({
        model: model,
        end_value: end_value,
        transient_end_value: transient_end_value,
        filtered: filtered,
        data: details.explore_data,
        lines: details.explore_lines,
        is_NA: details.explore_is_NA,
        onResetParameters: _this.props.get('onResetParameters')
      });
    };
    this.listenTo(this.props, 'change:print', function () {
      var print = _this.props.get('print');
      if (!print && _this.print_views) {
        each(_this.print_views, function (view) {
          view.remove();
        });
        _this.print_views = null;
      } else if (print) {
        if (!_this.print_views) {
          _this.print_views = {
            prevalence_all: createPrintDetails(bindPrevalence, prevalence, {
              age_group: '18-99'
            }),
            prevalence_18_24: createPrintDetails(bindPrevalence, prevalence, {
              age_group: '18-24'
            }),
            prevalence_25_44: createPrintDetails(bindPrevalence, prevalence, {
              age_group: '25-44'
            }),
            prevalence_45_64: createPrintDetails(bindPrevalence, prevalence, {
              age_group: '45-64'
            }),
            prevalence_65p: createPrintDetails(bindPrevalence, prevalence, {
              age_group: '65p'
            }),
            prevalence_reduction_all: createPrintDetails(bindPrevalenceReduction, prevalence_reduction, {
              age_group: '18-99'
            }),
            prevalence_reduction_18_24: createPrintDetails(bindPrevalenceReduction, prevalence_reduction, {
              age_group: '18-24'
            }),
            prevalence_reduction_25_44: createPrintDetails(bindPrevalenceReduction, prevalence_reduction, {
              age_group: '25-44'
            }),
            prevalence_reduction_45_64: createPrintDetails(bindPrevalenceReduction, prevalence_reduction, {
              age_group: '45-64'
            }),
            prevalence_reduction_65p: createPrintDetails(bindPrevalenceReduction, prevalence_reduction, {
              age_group: '65p'
            })
          };
        }
      }
      _this.render();
    });
  },
  render: function render() {
    var print = this.props.get('print');
    var exploring = this.props.get('exploring');
    this.template = print ? print_template : template;
    Component.prototype.render.call(this);
    if (this.printing && !print) {
      each(this.views, function (view) {
        return view.trigger('deactivate');
      });
      this.printing = false;
    }
    if (!print && exploring !== 'overview') {
      this.showTab(exploring);
    } else if (print) {
      this.printing = true;
      window.__no_transition = true;
      this.views.deaths_avoided.trigger('activate');
      this.views.deaths_avoided.render();
      this.views.lys_gained.trigger('activate');
      this.views.lys_gained.render();
      var rendering = [];
      each(this.print_views, function (view) {
        view.trigger('activate');
        view.render();
        rendering.push(view.data.get());
      });
      Promise.all(rendering).then(function () {
        defer(function () {
          window.__no_transition = false;
          window.printing && window.printing.resolve();
        });
      })["catch"](function (error) {
        window.__no_transition = false;
        window.printing && window.printing.reject(error);
      });
    }
    return this;
  },
  onNavClick: function onNavClick(e) {
    e.preventDefault();
    var id = $(e.currentTarget).attr('aria-controls').replace('explorer-', '');
    this.props.set('exploring', id);
  },
  onOverviewClick: function onOverviewClick(e) {
    var is_reset = $(e.target).hasClass('reset-na-parameters');
    if (is_reset) return;
    var id = $(e.currentTarget).data('id');
    this.props.set('exploring', id);
  },
  showTab: function showTab(id) {
    this.$('.tab-pane').removeClass('active');
    this.$("#explorer-".concat(id)).addClass('active');
    this.$('.explorer-nav-link').removeClass('active');
    this.$("[aria-controls=\"explorer-".concat(id, "\"]")).addClass('active');
    if (this.active_view) {
      this.active_view.trigger('deactivate');
      this.active_view = null;
    }
    if (this.views[id]) {
      this.active_view = this.views[id];
      this.active_view.trigger('activate');
      if (!this.rendered[id]) {
        this.active_view.render();
        this.rendered[id] = true;
      }
    }
    track(id, 'show-chart');
  },
  onResetNAParameters: function onResetNAParameters() {
    var reset = this.props.get('onResetParameters');
    if (!reset) return;
    reset();
  },
  attachChart: function attachChart(chart) {
    this.charts.push(chart);
  }
});
export default ExplorerComponent;
ExplorerComponent.registerAs('explorer');
function toValueClass(value) {
  return "explorer-nav-value".concat(Array.isArray(value) ? ' mod-range' : '');
}