import { assign } from 'lodash';
import { format as d3_format } from 'd3';
import { format, domains } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import App from '../../app';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
var progress_bar = App.template('progress-bar');
export var choropleth = {
  policy: 'taxes',
  title: 'Price per pack of cigarettes by state (2017)',
  source: '',
  domain: [4.5, 10],
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    var percent = (d.ppp - 4.5) / 5.5 * 100;
    var offset = percent < 15;
    var fill = stateFill(state);
    var color = offset ? '#000' : labelColor(state);
    var progress = progress_bar({
      percent: percent,
      percent_text: '$' + d.ppp,
      fill: fill,
      color: color,
      offset: offset
    });
    return "Price per pack (2017):<br>\n      ".concat(progress, "\n      <div class=\"hint\">\n        <strong>Hint:</strong> Click the state to set the initial price (rounded to nearest 50 cents) and to see the estimated impact.\n      </div>");
  },
  fillValue: function fillValue(d) {
    return d.ppp;
  },
  legend_format: d3_format('$,.2f'),
  can_click_states: true,
  include_overlay_state_title: true
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].taxes.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].taxes.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].taxes.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].taxes.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].taxes.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].taxes.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].taxes.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].taxes.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
function getSubtitle(choices) {
  var cohort_text = '';
  if (choices.by_cohort && choices.stratification != 'all' && choices.stratification != 'all-by-age') {
    cohort_text = "".concat(choices.stratification, " birth-cohort | ");
  }
  return "".concat(cohort_text, "Initial price : ").concat(format.money(choices.initial_price), "\n | Tax increase : ").concat(format.money(choices.tax_increase), "\n | New price : ").concat(format.money(choices.initial_price + choices.tax_increase));
}